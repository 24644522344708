import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { getOrderSteps } from 'helpers/utilHelper';

const FormSteps = props => {
  const { currentStep, order, setNavRoutes, onChange } = props;

  useEffect(() => {
    setNavRoutes(routes => ({
      next: steps[currentIndex() + 1]?.route || routes.next,
      prev: steps[currentIndex() - 1]?.route || routes.prev,
      availablePrevs: steps.slice(0, currentIndex()) || routes.prev,
    }));
  }, [currentStep, order.id]);

  /********** WIZARD CONFIG **********/

  // the list of wizard steps to display
  const steps = getOrderSteps(order);

  // send the selected step index to the parent component
  // in order to know in the current page(FormNewComponent) how to go Next/Prev based on the selected step
  const goToStep = (index) => onChange({ stepIndex: index });

  const currentIndex = () => steps.findIndex(s => s.key == currentStep);

  return <React.Fragment>
    <div className="wizard clearfix">
      <div className="steps clearfix">
        <ul>
          {steps.map((step, index) => {
            const isCompleted = index < currentIndex();
            const isClickable = index == +(currentIndex() + 1) || isCompleted;
            const isCurrentStep = currentStep === step.key;

            return <NavItem className={`${classnames({ 'cursor-pointer': isClickable, current: isCurrentStep, completed: isCompleted })} col-xl-2`} key={index}>
              <NavLink className={classnames({ 'cursor-pointer': isClickable, current: isCurrentStep, completed: isCompleted })} onClick={() => goToStep(index)} >
                <span className="number">{index + 1}.</span> {step.name}
              </NavLink>
            </NavItem>
          })}
        </ul>
      </div>
    </div>
  </React.Fragment>
}

FormSteps.propTypes = {
  currentStep: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  setNavRoutes: PropTypes.func,
  onChange: PropTypes.func,
}

export default FormSteps;