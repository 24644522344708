import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import { Container, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import { showError, showSuccess } from "helpers/utilHelper";
import Col from "components/Shared/Col";
import { generateOrderLabel } from "helpers/backendHelper";
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { doOrderSingleCleanup, getFullOrder } from "store/actions";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import ShippingParties from "./ShippingParties";
import Order from "model/order";
import ShippingMethod from "./ShippingMethod";
import ShipmentSummary from "./ShipmentSummary";

// !!!!WARNING!!!!
// This component, along with ShipmentSummary, ShippingMethod and ShippingParties
// are NOT used at the moment but we need to keep them for later changes
// Please do NOT DELETE IT

const GenerateLabel = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);

  // the index of the active tab
  const [activeTab, setActiveTab] = useState(0);
  // list of tab indexes that have been completed so far
  // includes the active tab
  const [passedSteps, setPassedSteps] = useState([0]);
  // stores all field values gathered across the wizard tabs
  const [fieldValues, setFieldValues] = useState({});
  // rating received from fedex/ups
  const [deliveryRating, setDeliveryRating] = useState(null);
  // whether generating label is in progress
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** EFFECTS /**********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    refreshOrder();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  useEffect(() => {
    if (isEmpty(fieldValues)) {
      // nothing has been saved yet so we are in the first wizard step
      return;
    }
    // if we have reached the end of the wizard
    if (activeTab == tabs.length - 1) {
      // save the data
      saveWizard();
    } else {
      // activate the next tab
      showTab(activeTab + 1);
    }
  }, [fieldValues]);

  /********** TAB CONFIG **********/

  // the list of tabs to display
  const tabs = [
    {
      name: 'Shipping Parties',
      component: ShippingParties,
    }, {
      name: 'Shipping Method',
      component: ShippingMethod,
    }, {
      name: 'Shipment Summary',
      component: ShipmentSummary,
      props: { isSaveInProgress }
    }
  ];

  // the component to be rendered for the active tab
  const ActiveTabComponent = tabs[activeTab].component;
  const activeTabProps = tabs[activeTab].props;

  /********** EVENT HANDLERS **********/

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  // makes the specified tab visible
  const showTab = tab => {
    // if tab is not already active
    if (activeTab !== tab) {
      // add the tab to the completed tabs list
      const prevSteps = [...passedSteps, tab];
      setPassedSteps(prevSteps);
      // activate the tab
      setActiveTab(tab);
    }
  }

  // triggered when a tab form is successfully submitted
  const onTabNext = (tabId, values) => {
    // add the field values to the global object under the tab id
    const merged = {
      ...fieldValues,
      [tabId]: values,
    };
    setFieldValues(merged);
  }

  // triggered when the user clicks the 'back' button in a tab
  const onTabPrev = () => {
    if (activeTab == 0) {
      // the user pressed the 'quit' button so close the wizard
      navigate(route(routes.view_order, id));
    } else {
      // activate the previous tab
      showTab(activeTab - 1);
    }
  }

  /********** OTHER **********/

  // makes the remote call to save the dealer store
  const saveWizard = () => {
    // flatten the values object
    // because the fields are stored by tab id
    const values = Object.values(fieldValues).reduce((prev, curr) => ({ ...prev, ...curr }), {});
    // fire the request
    generateLabel(values);
  }

  // get the stored field values for the active tab
  // we use this to populate the form fields when navigating back to a completed tab
  const getTabDefaultValues = () => fieldValues[activeTab] || {};

  const generateLabel = values => {
    setIsSaveInProgress(true);
    const destructuredValues = { ...values.deliveryMethod, ...values.packagingType, ...values }
    generateOrderLabel(order.id, destructuredValues)
      .then(response => {
        showSuccess('The label has been generated');
        navigate(route(routes.view_order, order.id));
      })
      .catch(ex => {
        showError('Unable to generate label');
      })
      .finally(() => {
        setIsSaveInProgress(false);
      })
  }

  return <React.Fragment>
    {iAmGranted(perms.edit_orders) && <div className="page-content">
      {
        !!order && order.status === Order.STATUS_GENERATE_SHIPPING_LABEL && <>
          <MetaTitle>Generate Label</MetaTitle>
          <Container fluid>
            <Breadcrumbs breadcrumbItems={breadcrumbs(id)} />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix pb-4">
                        <ul>
                          {tabs.map((tab, index) => {
                            return <NavItem className={classnames({ current: activeTab === index })} key={index}>
                              <NavLink className={classnames({ active: activeTab === index })} onClick={() => showTab(index)} disabled={!passedSteps.includes(index)}>
                                <span className="number">{index + 1}</span> {tab.name}
                              </NavLink>
                            </NavItem>
                          })}
                        </ul>
                      </div>
                      <div className="content clearfix p-0 ps-xl-2">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={activeTab}>
                            <ActiveTabComponent tabId={activeTab} nextHandler={onTabNext} prevHandler={onTabPrev} defaultValues={getTabDefaultValues()} {...activeTabProps} order={order} setDeliveryRating={setDeliveryRating} deliveryRating={deliveryRating} allValues={fieldValues} />
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      }
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {(iAmNotGranted(perms.edit_orders) || (!!order && order.status !== Order.STATUS_GENERATE_SHIPPING_LABEL)) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = (id) => [{
  title: 'Order',
  url: route(routes.view_order, id),
}, {
  title: 'Generate Label',
}];

export default GenerateLabel;
