import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Row, UncontrolledTooltip, Button, ButtonGroup, Input, Label } from "reactstrap";
import classnames from "classnames";
import Order from 'model/order';
import OrderDoc from 'model/orderDoc';
import VidRequest from 'model/vidRequest';
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import locationChangeIcon from 'assets/images/location-change.svg';
import locationChangeDenyIcon from 'assets/images/location-change-deny.svg';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import notaryServiceIcon from 'assets/images/notary-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import multipleSignersIcon from 'assets/images/multiple-signers.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import noThumbprintIcon from 'assets/images/no-thumbprint-icon.svg';
import routeOneIcon from 'assets/images/route-one.svg';
import generateLabelIcon from 'assets/images/generate-label-icon.svg';
import { getOrderInkSignStatus, getOrderESignStatus, getOrderVidStatus, getESignOrderDocs, addESign, removeESign } from "helpers/backendHelper";
import { ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS, ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE, ORDER_ESIGN_SERVICE_ALREADY_ADDED, ORDER_ESIGN_SERVICE_NOT_ADDED, ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN } from 'helpers/errorHelper';
import { doOrderDocNormListCleanup, getFullOrder } from 'store/actions';
import { getGranularStatusName, getVIDStatusImage, showError, showSuccess } from 'helpers/utilHelper';
import Col from "../../../../components/Shared/Col";
import inactiveIcon from "../../../../assets/images/inactive-order.svg";
import editIcon from "assets/images/edit-icon.svg";
import editServiceIcon from "assets/images/edit-services-icon.svg";
import Confirmation from 'components/Shared/Confirmation';
import { perms, useAccess } from "context/access";
import { useDispatch } from 'react-redux';
import { getPassedDuration, timestamp } from 'helpers/dateHelper';

const SectionOrderActions = ({ order, id, refreshHandler }) => {

  const dispatch = useDispatch();
  const { iAmGranted } = useAccess();

  /********** STATE **********/

  const [inkSignStatus, setInkSignStatus] = useState(null);
  const [inkSignStatusError, setInkSignStatusError] = useState(null);

  const [eSignStatus, setESignStatus] = useState(null);
  const [eSignStatusError, setESignStatusError] = useState(null);

  const [vidStatus, setVidStatus] = useState(null);
  const [vidStatusError, setVidStatusError] = useState(null);

  const [eSignOrderDocs, setESignOrderDocs] = useState([]);

  // Order Services
  const [isAddEsignChanged, setIsAddEsignChanged] = useState(false);
  const [isRemoveEsignChanged, setIsRemoveEsignChanged] = useState(false);
  const [isEsignServiceChanged, setIsEsignServiceChanged] = useState(false);
  const [isEsignChecked, setIsEsignChecked] = useState(order.isEsignRequired);

  //  Services modals
  const [confirmOrderServicesVisible, setConfirmOrderServicesVisible] = useState(false);
  const [confirmAddEsignServicesVisible, setConfirmAddEsignServicesVisible] = useState(false);
  const [confirmRemoveEsignServicesVisible, setConfirmRemoveEsignServicesVisible] = useState(false);
  const [confirmDeleteDocumentsFirstVisible, setConfirmDeleteDocumentsFirstVisible] = useState(false);

  const isEsignOnly = () => !order.isVidRequired && !order.isInkSignRequired;

  const isOrderCancelled = () => order?.status == Order.STATUS_CANCELLED;

  const isOrderCompleted = () => order.status === Order.STATUS_COMPLETED;

  const isOrderSeald = () => order.status === Order.STATUS_SEALED;

  const isRemoteOrder = () => order.customerLocation === Order.CUSTOMER_LOCATION_REMOTE;

  const isEditServicesVisible = () => iAmGranted(perms.add_order_services) && !isOrderCompleted() && !isOrderSeald() && isRemoteOrder();;

  // Can Add Esign on order whenever it is not completed or cancelled
  const canAddEsignService = () => !order.isEsignRequired && ![Order.STATUS_CANCELLED, Order.STATUS_COMPLETED].includes(order.status) && isRemoteOrder();

  // Esign can be removed from the order as long as there are no documents added in the esign section
  // Esign cannot be removed if its the only service on the order.
  const canRemoveEsignService = () => !isEsignOnly() && ![Order.STATUS_CANCELLED, Order.STATUS_COMPLETED].includes(order.status) && isRemoteOrder();;

  const isEditServiceDisabled = () => !(canAddEsignService() || canRemoveEsignService());

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [editServicesVisible, setEditServicesVisible] = useState(false);

  //Update screen width state variable
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  // Calculate the time difference in days/hours
  const getTimePassed = () => {
    let timePassed = "";
    const { days, hours } = getPassedDuration(order.updatedTs, timestamp())

    if (days > 0) {
      timePassed = timePassed + `${days}d`
    }
    if (hours > 0) {
      timePassed = timePassed + `${hours}h`
    }

    return timePassed;
  }

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the data
    if (order.isInkSignRequired)
      refreshOrderInkSignStatus();
  }, [id]);

  useEffect(() => {
    if (order.isEsignRequired)
      refreshOrderESignStatus();
  }, [id]);

  useEffect(() => {
    if (order.isVidRequired);
    refreshOrderVidStatus();
  }, [id]);

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, [screenWidth]);

  useEffect(() => {
    // make the initial remote call to get the data
    refreshESignOrderDocs();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDocNormListCleanup());
    }
  }, [order.id]);

  /********** OTHER **********/

  const refreshOrderInkSignStatus = () => {
    getOrderInkSignStatus(id)
      .then(response => {
        setInkSignStatus(response.inkSign);
      })
      .catch(ex => {
        setInkSignStatusError(ex);
      })
  };

  const refreshOrderESignStatus = () => {
    getOrderESignStatus(id)
      .then(response => {
        setESignStatus(response.eSign);
      })
      .catch(ex => {
        setESignStatusError(ex);
      })
  };

  const refreshOrderVidStatus = () => {
    // make the initial remote call to get the data
    getOrderVidStatus(id)
      .then(response => {
        setVidStatus(response.vid);
      })
      .catch(ex => {
        setVidStatusError(ex);
      })
  };

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  const refreshESignOrderDocs = () => {
    getESignOrderDocs(id)
      .then(response => {
        setESignOrderDocs(response.orderDocs);
      })
      .catch(err => {
        console.log('error')
      })
  };

  // Get e sign status value
  const getEsignServiceStatus = (eSignService) => {
    // Close edit services modal
    setEditServicesVisible(false);

    if (!eSignService) {
      // If there are documents in the Esgin section and the user wants to remove the esign service
      // display a message to remove the documents first
      if (eSignOrderDocs?.length > 0) setConfirmDeleteDocumentsFirstVisible(true);

      // If there are no documents in the Esgin section and the user wants to remove the esign service
      // Open confirm Remove Esign service modal
      if (eSignOrderDocs?.length === 0) setConfirmRemoveEsignServicesVisible(true);
    }

    if (eSignService) {
      // Open confirm Add Esign service modal
      setConfirmAddEsignServicesVisible(true);
    }

    setIsEsignServiceChanged(true);
  }

  //  Add Esign Service
  const handleAddEsignService = () => {
    addESign(id)
      .then(() => {
        showSuccess("E-sign service added with success.");
        refreshOrder();
        refreshOrderESignStatus();
        refreshESignOrderDocs();
        refreshHandler();
      })
      .catch(error => {
        switch (error.code) {
          case ORDER_ESIGN_SERVICE_ALREADY_ADDED:
            showError("E-sign service already added");
            break;
          case ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN:
            showError("Service cannot be added in this order status");
            break;
          default:
            showError("Unable to add e-sign service");
            break;
        }
      });
  }

  //  Remove Esign Service
  const handleRemoveEsignService = () => {
    removeESign(id)
      .then(() => {
        showSuccess("E-sign service removed with success.");
        refreshOrder();
        refreshOrderESignStatus();
        refreshESignOrderDocs();
        refreshHandler();
      })
      .catch(error => {
        switch (error.code) {
          case ORDER_ESIGN_SERVICE_NOT_ADDED:
            showError("E-sign service not present");
            break;
          case ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS:
            showError("Please remove all e-sign documents before removing the service");
            break;
          case ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN:
            showError("Service cannot be removed in this order status");
            break;
          case ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE:
            showError("Cannot remove the only service");
            break;
          default:
            showError("Unable to remove e-sign service");
            break;
        }
      })
  }

  // Confirm order services
  const handleConfirmOrderServices = () => {
    if (isEsignServiceChanged) {
      if (isAddEsignChanged) handleAddEsignService();

      if (isRemoveEsignChanged) handleRemoveEsignService();
    }

    //  Reset E-sign Services
    setIsEsignServiceChanged(false);
    setIsAddEsignChanged(false);
    setIsRemoveEsignChanged(false);

    setConfirmOrderServicesVisible(false);
  }

  const color = Order.getGranularStatusColor(order.granularStatus);

  const renderActionButtons = () => {
    return (<div className="col-5 d-flex align-items-center justify-content-end display-action-buttons">
      {/* Edit E-sign Service */}
      {isEditServicesVisible() && <ButtonGroup className="pe-2">
        <Button color="primary" className="square-icon-btn" onClick={() => setEditServicesVisible(true)}>
          <img src={editIcon} className="mdi mdi-close font-size-18 mt-1" alt="edit-icon" />
        </Button>
        <Button color="primary" className="btn py-1" onClick={() => setEditServicesVisible(true)}>
          Edit Services
        </Button>
      </ButtonGroup>}
    </div>)
  }

  const renderDesktopView = () => {
    return (<div className='display-divs d-flex'>
      <div className="col-3 pe-0">
        <span>
          <div className="row mb-2">
            <div className='col-6 header-title font-weight-semibold'>Order ID {order.id}</div>
            {order.isInactive && order.status != Order.STATUS_CANCELLED && (<div className='col-6 d-flex align-items-center justify-content-end'>
              <img src={inactiveIcon} />
              <span className="ms-2">Inactive({getTimePassed()})</span>
            </div>)}
          </div>
          <div>
            <p className='d-inline font-weight-normal header-stats'>Status:</p>
            <span className={`badge header-badge rounded-pill bg-${color} mx-2`}>{getGranularStatusName(order.granularStatus)}</span>
            <span>
              {order.isNotaryRequired && <><img id='notary-service' src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='notary-service'>Notary service</UncontrolledTooltip></>}
              {order.isRushRequired && <><img id='rush-order' src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='rush-order'>Rush order</UncontrolledTooltip></>}
              {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
                <><img id={`doc-delivery-shipping`} src={docDeliveryShippingIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-shipping`}>Documents shipping</UncontrolledTooltip></>
              }
              {order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
                <><img id={`doc-delivery-upload`} src={docDeliveryUploadIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-upload`}>Documents upload</UncontrolledTooltip></>
              }
              {order?.signers?.length > 1 && <><img id='multiple-signers' src={multipleSignersIcon} className='me-2' /><UncontrolledTooltip placement="top" target='multiple-signers'>Multiple signers</UncontrolledTooltip></>}
              {order.notaryMustUploadDocs && <><img id='must-upload-docs' src={mustUploadDocsIcon} className='me-2 must-upload-icon' /><UncontrolledTooltip placement="top" target='must-upload-docs'>Notary must upload docs</UncontrolledTooltip></>}
              {!order.isAoiThumbprintRequired && order.isNotaryRequired && <><img id='no-thumbprint-aoi' src={noThumbprintIcon} className='me-2' /><UncontrolledTooltip placement="top" target='no-thumbprint-aoi'>No Thumbprint AOI</UncontrolledTooltip></>}
              {order?.r1DealJacketId && <><img id={`route-one`} src={routeOneIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`route-one`}>Route One</UncontrolledTooltip></>}
              {order.hasMavShippingLabel && order.isNotaryRequired && <><img id='generate-label' src={generateLabelIcon} className="me-2" /><UncontrolledTooltip placement="top" target='generate-label'>Label Generation</UncontrolledTooltip></>}
            </span>
          </div>
        </span>
      </div>
      <div className="col-4 display-status d-flex">
        {order.isVidRequired && !!vidStatus && <>
          <div className="divider me-4"></div>
          <div className='d-flex align-items-start'>
            {getVIDStatusImage(order.vidStatus)}
            <div className='d-flex flex-column'>
              <span className='d-inline header-stats'>Identity Validation Overview</span>
              {!!vidStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                <div className={`badge header-badge rounded-pill mt-2 bg-${VidRequest.getStatusColor(vidStatus.status)}`}>{vidStatus.statusName}</div>
              }
            </div>
          </div>
        </>}
        {order.isEsignRequired && !!eSignStatus && <>
          <div className="divider me-4 ms-4"></div>
          <div className='d-flex align-items-start'>
            <img id='e-sign' src={eSignServiceIcon} className="me-2" />
            <div className='d-flex flex-column'>
              <span className='d-inline header-stats'>e-Signed Contract</span>
              {!!eSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(eSignStatus.status)}`}>{eSignStatus.statusName}</div>}
            </div>
          </div>
        </>}
        {order.isInkSignRequired && !!inkSignStatus && <>
          <div className="divider me-4 ms-4"></div>
          <div className='d-flex align-items-start'>
            <img id='ink-sign' src={inkSignServiceIcon} className="me-2" />
            <div className='d-flex flex-column'>
              <span className='d-inline header-stats'>Ink Signed Contract</span>
              {!!inkSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(inkSignStatus.status)}`}>{inkSignStatus.statusName}</div>}
            </div>
          </div>
        </>}
        {order.isNotaryRequired && <>
          <div className="divider me-4 ms-4"></div>
          <div className='d-flex align-items-start'>
            <img src={`${order.canChangeSigningLocation ? locationChangeIcon : locationChangeDenyIcon}`} className="me-2" />
            <div className='d-flex flex-column'>
              <span className='d-inline header-stats'>Location Change</span>
              <div className={`badge header-badge rounded-pill mt-2 bg-${order.canChangeSigningLocation ? 'success' : 'danger'}`}>
                {order.canChangeSigningLocation ? 'Allowed' : 'Not Allowed'}
              </div>
            </div>
          </div>
        </>}
      </div>

      {/* render buttons for order actions */}
      {renderActionButtons()}
    </div>)
  }

  const renderTabletView = () => {
    return (<div className='display-divs'>
      <Row className='mb-4'>
        <Col xs={12} sm={6}>
          <span>
            <div className="row mb-2">
              <div className='col-6 header-title font-weight-semibold'>Order ID {order.id}</div>
              {order.isInactive && order.status != Order.STATUS_CANCELLED && (<div className='col-6 d-flex align-items-center justify-content-end'>
                <img src={inactiveIcon} />
                <span className="ms-2">Inactive({getTimePassed()})</span>
              </div>)}
            </div>
            <div>
              <p className='d-inline font-weight-normal header-stats'>Status:</p>
              <span className={`badge header-badge rounded-pill bg-${color} mx-2`}>{getGranularStatusName(order.granularStatus)}</span>
              <span>
                {order.isNotaryRequired && <><img id='notary-service' src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='notary-service'>Notary service</UncontrolledTooltip></>}
                {order.isRushRequired && <><img id='rush-order' src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target='rush-order'>Rush order</UncontrolledTooltip></>}
                {order?.signers?.length > 1 && <><img id='multiple-signers' src={multipleSignersIcon} className='me-2' /><UncontrolledTooltip placement="top" target='multiple-signers'>Multiple signers</UncontrolledTooltip></>}
              </span>
            </div>
          </span>
        </Col>
        <Col xs={12} sm={12}>
          <div className="d-flex">
            {renderActionButtons()}
          </div>
        </Col>
      </Row>
      <Row>
        <div className="display-status d-flex">
          {order.isVidRequired && !!vidStatus && <>
            <div className='d-flex align-items-start'>
              {getVIDStatusImage(order.vidStatus)}
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Identity Validation Overview</span>
                {!!vidStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${VidRequest.getStatusColor(vidStatus.status)}`}>{vidStatus.statusName}</div>
                }
              </div>
            </div>
            <div className="divider me-4 ms-4"></div>
          </>}
          {order.isEsignRequired && !!eSignStatus && <>
            <div className='d-flex align-items-start'>
              <img id='e-sign' src={eSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>e-Signed Contract</span>
                {!!eSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(eSignStatus.status)}`}>{eSignStatus.statusName}</div>}
              </div>
            </div>
          </>}
          {order.isInkSignRequired && !!inkSignStatus && <>
            <div className="divider me-4 ms-4"></div>
            <div className='d-flex align-items-start'>
              <img id='ink-sign' src={inkSignServiceIcon} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Ink Signed Contract</span>
                {!!inkSignStatusError ? <div className='badge header-badge rounded-pill mt-2 bg-dark'>{'Unable to load status'}</div> :
                  <div className={`badge header-badge rounded-pill mt-2 bg-${OrderDoc.getStatusColor(inkSignStatus.status)}`}>{inkSignStatus.statusName}</div>}
              </div>
            </div></>}
          {order.isNotaryRequired && <>
            <div className="divider me-4 ms-4"></div>
            <div className='d-flex align-items-start'>
              <img id='ink-sign' src={`${order.canChangeSigningLocation ? locationChangeIcon : locationChangeDenyIcon}`} className="me-2" />
              <div className='d-flex flex-column'>
                <span className='d-inline header-stats'>Location Change</span>
                <div className={`badge header-badge rounded-pill mt-2 bg-${order.canChangeSigningLocation ? 'success' : 'danger'}`}>
                  {order.canChangeSigningLocation ? 'Allowed' : 'Not Allowed'}
                </div>
              </div>
            </div>
          </>}
        </div>
      </Row>
    </div>)
  }

  const renderView = () => {
    if (screenWidth >= 1200) {
      return renderDesktopView();
    } else {
      return renderTabletView();
    }
  };

  return <React.Fragment>
    {isOrderCancelled() && <Alert color="danger">
      <i className="bx bx-block label-icon ms-2"></i> This order has been Canceled
    </Alert>}

    <div className={isOrderCancelled() ? 'blurred' : undefined}>
      <Card className="overflow-hidden mb-0" style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
        <CardBody className="px-0 pb-0">
          <div className={classnames('card-section pb-3')}>
            {renderView()}
          </div>
        </CardBody>
      </Card>
    </div>

    {/* Services Modal */}
    {editServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 600, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      title="Edit Services"
      confirmBtnText="Confirm"
      cancelBtnText="Close"
      onConfirm={() => {
        setEditServicesVisible(false);
        setConfirmOrderServicesVisible(true);
      }}
      onCancel={() => {
        setIsEsignServiceChanged(false);
        setEditServicesVisible(false);
        // reset the check value for eSign with the value from the order
        setIsEsignChecked(order.isEsignRequired);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
      disabled={isEditServiceDisabled()}
    >
      <div className="d-flex justify-content-center align-items-center my-3">
        <Col sm={8}>
          <React.Fragment>
            {/* E-sign - Service */}
            <Row className="mt-2">
              <Col sm={6}>
                <div className="d-flex align-items-center">
                  <img src={eSignServiceIcon} className="me-2" />
                  <div className="font-size-13 font-weight-normal">E-sign</div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-check form-switch form-switch-lg d-inline-block">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="esign"
                    onChange={e => {
                      getEsignServiceStatus(e.target.checked)
                    }}
                    checked={isEsignChecked}
                    disabled={!(canAddEsignService() || canRemoveEsignService())}
                  />
                  <Label className="form-check-label" htmlFor="esign" />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </Col>
      </div>
    </Confirmation>}

    {/* Confirm Add Order Services Modal */}
    {confirmOrderServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => handleConfirmOrderServices()}
      onCancel={() => {
        // Reset services state
        setIsEsignServiceChanged(false);
        setIsAddEsignChanged(false);
        setIsRemoveEsignChanged(false);

        setConfirmOrderServicesVisible(false);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Col sm={8}>
          <span style={{ color: '#556EE6', fontSize: 15 }}>Changing the settings here will impact the progress of the order and may entail additional fees!</span>
        </Col>
      </div>
    </Confirmation>}

    {/* ADD E-sign - Modal */}
    {confirmAddEsignServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => {
        setIsAddEsignChanged(true);
        setIsEsignChecked(true);

        setIsRemoveEsignChanged(false);

        setConfirmAddEsignServicesVisible(false);
        setEditServicesVisible(true);
      }}
      onCancel={() => {
        setIsAddEsignChanged(false);
        setConfirmAddEsignServicesVisible(false);
        setConfirmOrderServicesVisible(false);
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>Electronic Signature will be added as a service on this order. The documents to be signed need to be uploaded in the <strong>E-Signed Documents</strong>. After the signature placement, the Customer will be notified to Electronically Sign the documents.</span>
        </Col>
      </div>
    </Confirmation>}

    {/* REMOVE E-sign - Modal */}
    {confirmRemoveEsignServicesVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      onConfirm={() => {
        setIsRemoveEsignChanged(true);
        setIsEsignChecked(false);

        setIsAddEsignChanged(false);

        setConfirmRemoveEsignServicesVisible(false);
        setEditServicesVisible(true);
      }}
      onCancel={() => {
        setIsRemoveEsignChanged(false);
        setConfirmRemoveEsignServicesVisible(false);
        setConfirmOrderServicesVisible(false);
        setEditServicesVisible(true);
      }}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>Electronic Signature will be removed. The service will no longer be available for this order.</span>
        </Col>
      </div>
    </Confirmation>}

    {/* Confirm Delete Documents First - To remove E-sign  */}
    {confirmDeleteDocumentsFirstVisible && <Confirmation
      style={{ backgroundColor: 'white', minWidth: 350, paddingBottom: 35 }}
      customIcon={editServiceIcon}
      showCancel={false}
      onConfirm={() => setConfirmDeleteDocumentsFirstVisible(false)}
      closeOnClickOutside={false}
      reverseButtons={false}
    >
      <div className="d-flex justify-content-center">
        <Col sm={10}>
          <span style={{ color: '#556EE6', fontSize: 15, textAlign: 'left' }}>There are Documents added in the <strong>E-Signed Documents</strong> section, so the E-sign service cannot be removed. First delete the documents, and then remove the E-sign service from the order.</span>
        </Col>
      </div>
    </Confirmation>}
  </React.Fragment>
}

SectionOrderActions.propTypes = {
  order: PropTypes.object,
  id: PropTypes.number,
  refreshHandler: PropTypes.func,
};

export default SectionOrderActions;
