import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Row, Form, Label, Input, FormFeedback, Button, Card, CardHeader, CardBody, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { capitalize, dictionaryToSelectOptions, getYesNoOptions, nullsToEmptyStrings, showError, showSuccess, notaryMustUploadDocsOptions } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import Select from "react-select";
import Order from "model/order";
import DatePicker from "components/Shared/DatePicker";
import TextareaAutosize from 'react-textarea-autosize';
import { updateOrderContract } from "helpers/backendHelper";
import infoIcon from "assets/images/info-icon.svg";

const FormEditContract = props => {

  const { defaultValues, finishedHandler } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);


  /********** FORM CONFIG **********/

  const formInitialValues = {
    docsSentToNotaryTs: null,
    itemsToCollect: [],
    additionalItemsToCollect: '',
    dealerInstructions: '',
    signer1Instructions: '',
    signer2Instructions: '',
    notaryMustUploadDocs: null,
    ...nullsToEmptyStrings(defaultValues, false, ['docsSentToNotaryTs', 'itemsToCollect', 'notaryMustUploadDocs']),
  };
  if (!!formInitialValues.itemsToCollect) {
    // the ids coming from the BE are strings, we need to convert them to numbers in order to match the select options
    formInitialValues.itemsToCollect = formInitialValues.itemsToCollect.map(item => +item);
  }

  const formValidationSchema = {
    docsSentToNotaryTs: Yup.mixed().when('docDeliveryOption', {
      is: Order.DOC_DELIVERY_OPTION_SHIPPING,
      then: Yup.number().nullable().required('Field is required'),
    }),
    // mandatory field
    notaryMustUploadDocs: Yup.boolean().nullable().required('Field is required'),
    // optional fields
    additionalItemsToCollect: Yup.string().trim().nullable(),
    dealerInstructions: Yup.string().trim().nullable(),
    signer1Instructions: Yup.string().trim().nullable(),
    signer2Instructions: Yup.string().trim().nullable(),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => saveOrderContract(values, props.id)
  });


  /********** EVENT HANDLERS **********/

  // on change event handler that capitalizes user input
  const capitalizeTextOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, capitalize(event.target.value))
  };

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const getItemToCollectOptions = () => dictionaryToSelectOptions(Order.getItemToCollectMap());

  const saveOrderContract = (values, id) => {
    setIsSaveInProgress(true);
    updateOrderContract(values, id)
      .then(response => {
        showSuccess(`Contract information has been saved`);
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save contract information');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  };

  return <React.Fragment>
    <Card className="expand-v overflow-hidden">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Contract Information</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={() => finishedHandler()}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form className="pt-1">
          {defaultValues.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING && (
            <Row className="mb-4">
              <Col xl="12">
                <Label>Date when documents will be sent to Notary *</Label>
                <><img id='info-icon' src={infoIcon} className='ms-2' alt='info-icon' /><UncontrolledTooltip placement="top" target='info-icon'>Please be advised the notary is assigned based on the date submitted</UncontrolledTooltip></>
                <DatePicker
                  required
                  name="docsSentToNotaryTs"
                  onChange={selected => formik.setFieldValue("docsSentToNotaryTs", selected)}
                  onFocus={onFieldFocused}
                  value={formik.values.docsSentToNotaryTs}
                  minDate={new Date().setHours(0, 0, 0, 0)}
                  invalid={!!formik.errors.docsSentToNotaryTs}
                  format="d M, Y" />
                {!!formik.errors.docsSentToNotaryTs && <FormFeedback type="invalid">{formik.errors.docsSentToNotaryTs}</FormFeedback>}
              </Col>
            </Row>
          )}
          <Row>
            <Col xl="12" className="mb-4">
              <Label>
                Items to collect by the MavSign notary?
                {/* <i className="mdi mdi-information ms-1" id="itemsToCollectTooltip" />
                <UncontrolledTooltip placement="top" target="itemsToCollectTooltip">TOOLTIP_PLACEHOLDER</UncontrolledTooltip> */}
              </Label>
              <Select
                isMulti
                classNamePrefix="select2-selection"
                name="itemsToCollect"
                options={getItemToCollectOptions()}
                onChange={selected => formik.setFieldValue("itemsToCollect", selected?.map(item => item.value))}
                onFocus={e => onFieldFocused(e, "itemsToCollect")}
                value={getItemToCollectOptions().filter(option => formik.values.itemsToCollect?.includes(option.value))}
                className={!!formik.errors.itemsToCollect && "is-invalid"} />
              {!!formik.errors.itemsToCollect && <FormFeedback type="invalid">{formik.errors.itemsToCollect}</FormFeedback>}
            </Col>
            <Col xl="12" className="mb-4">
              <Label>Does the notary need to scan and upload signed DOCS? *</Label>
              <Select
                classNamePrefix="select2-selection"
                name="notaryMustUploadDocs"
                options={notaryMustUploadDocsOptions()}
                onChange={selected => formik.setFieldValue('notaryMustUploadDocs', selected.value)}
                onFocus={e => onFieldFocused(e, 'notaryMustUploadDocs')}
                value={notaryMustUploadDocsOptions().find(option => option.value === formik.values.notaryMustUploadDocs)}
                className={!!formik.errors.notaryMustUploadDocs && 'is-invalid'} />
              {!!formik.errors.notaryMustUploadDocs && <FormFeedback type="invalid">{formik.errors.notaryMustUploadDocs}</FormFeedback>}
              {!!formik.values.notaryMustUploadDocs &&
                <div className="mt-1 font-size-11"><i className="mdi mdi-information-outline me-1 font-size-12"></i>The wet-signed DOCS will be dropped after they are scanned - turnaround time is based on FedEx/UPS pick-up schedules. This could result in an additional fee.</div>
              }
            </Col>
            <Col xl="12" className="mb-4">
              <Label>
                Additional Items to Collect
                {/* <i className="mdi mdi-information ms-1" id="additionalItemsToCollectTooltip" />
                <UncontrolledTooltip placement="top" target="additionalItemsToCollectTooltip">TOOLTIP_PLACEHOLDER</UncontrolledTooltip> */}
              </Label>
              <TextareaAutosize
                className={classnames('form-control', { 'is-invalid': !!formik.errors.additionalItemsToCollect })}
                name="additionalItemsToCollect"
                onChange={capitalizeTextOnChange}
                onFocus={onFieldFocused}
                value={formik.values.additionalItemsToCollect} />
              {!!formik.errors.additionalItemsToCollect && <FormFeedback type="invalid">{formik.errors.additionalItemsToCollect}</FormFeedback>}
            </Col>
          </Row>
          {!!defaultValues.signers && defaultValues.signers.length > 1 && <Row className="mb-4">
            <Col xl="6">
              <Label>Specific Signing Instructions</Label>
              <Row className="mb-4">
                <Col className="d-flex no-wrap">
                  <Label className="text-nowrap mt-2 me-2">Signer 1</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name="signer1Instructions"
                    onChange={capitalizeTextOnChange}
                    onFocus={onFieldFocused}
                    value={formik.values.signer1Instructions}
                    invalid={!!formik.errors.signer1Instructions}
                  />
                  {!!formik.errors.signer1Instructions && <FormFeedback type="invalid">{formik.errors.signer1Instructions}</FormFeedback>}
                </Col>
              </Row>
              <Row>
                <Col className="d-flex no-wrap">
                  <Label className="text-nowrap mt-2 me-2">Signer 2</Label>
                  <Input
                    type="text"
                    className="form-control"
                    name="signer2Instructions"
                    onChange={capitalizeTextOnChange}
                    onFocus={onFieldFocused}
                    value={formik.values.signer2Instructions}
                    invalid={!!formik.errors.signer2Instructions}
                  />
                  {!!formik.errors.signer2Instructions && <FormFeedback type="invalid">{formik.errors.signer2Instructions}</FormFeedback>}
                </Col>
              </Row>
            </Col>
          </Row>}

          <Row>
            <Col>
              <Label>Dealer Special Instructions</Label>
              <TextareaAutosize
                minRows={3}
                className={classnames('form-control', { 'is-invalid': !!formik.errors.dealerInstructions })}
                placeholder={'i.e. Instructions for the notary during the signing, instructions for multiple signers or if the buyer is a company, instructions for Mavsign team, any additional instructions regarding the timing of the signing, language preference and the return of the documents signed.'}
                name="dealerInstructions"
                onChange={capitalizeTextOnChange}
                onFocus={onFieldFocused}
                value={formik.values.dealerInstructions} />
              {!!formik.errors.dealerInstructions && <FormFeedback type="invalid">{formik.errors.dealerInstructions}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormEditContract.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
};

export default FormEditContract;