export class BeException extends Error {

  constructor(message, code, prev) {
    super(message);
    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor)
    }
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class AuthException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class AccessDeniedException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Access denied', code, prev);
  }
}

export class NotFoundException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Resource not found', code, prev);
  }
}

export class BadRequestException extends BeException {

  constructor(message, code, fields, prev) {
    super(message || 'Bad request', code, prev);
  }
}

export class ValidationException extends BadRequestException {

  constructor(message, fields) {
    super(message);
    this.fields = fields;
  }
}

export class UnprocessableEntityException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Unprocessable entity', code, prev);
  }
}

export class ServerErrorException extends BeException {

  constructor(message, code, prev) {
    super(message || 'Internal server error', code, prev);
  }
}

export class LocalException extends Error {

  constructor(message, code, prev) {
    super(message);
    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor)
    }
    this.name = this.constructor.name;
    this.code = code;
    this.prev = prev;
  }
}

export class NetworkErrorException extends LocalException {

  constructor(message, code, prev) {
    super(message || 'Network error', code, prev);
  }
}

export const ACC_TOKEN_MISSING = 1000;
export const ACC_TOKEN_JWT_EXPIRED = 1002;
export const UNABLE_SEND_NEW_ORDER_CUSTOMER_NOTIF = 1041;
export const UNABLE_SEND_NEW_ORDER_DEALER_NOTIF = 1042;
export const UNABLE_SEND_UPLOADED_DOCS_NOTIF = 1047;
export const ORDER_STATUS_INVALID_FOR_SIGNERS_RELOAD = 1048;
export const UNABLE_SEND_REJECT_DOC_NOTIF = 1053;
export const UNABLE_SEND_REJECT_PAGE_NOTIF = 1054;
export const UNABLE_SEND_NEW_MEETING_PARTICIPANT_NOTIF = 1058;
export const UNABLE_START_ORDER_DOC_PROCESSING = 1064;
export const UNABLE_DELETE_USER_DUE_TO_FOREIGN_CONSTRAINTS = 1073;
export const CANNOT_CHANGE_TO_FREE_PAYMENT_PLAN = 1082;
export const NOT_ALL_DOCS_RETURNED_BY_NOTARY = 1173;
export const ORDER_DOC_CANNOT_DELETE_LAST_PAGE = 1254;
export const UPLOADED_FILE_IS_ENCRYPTED = 1289;
export const UNABLE_INSERT_DOC_DUE_TO_UNIQUE_KEY = 1290;
export const ORDER_ESIGN_SERVICE_ALREADY_ADDED = 1297;
export const ORDER_CANNOT_REMOVE_ESIGN_SERVICE_WITH_DOCS = 1298;
export const ORDER_STATUS_INVALID_FOR_ADD_REMOVE_ESIGN = 1299;
export const ORDER_ESIGN_SERVICE_NOT_ADDED = 1300;
export const ORDER_CANNOT_REMOVE_THE_ONLY_SERVICE = 1301;
export const UNABLE_TO_VERIFY_UPLOADED_FILE = 1309;
export const ORDER_DOC_CONFLICTING_WITH_DELIVERY_OPTION = 1316;
export const ORDER_STATUS_INVALID_FOR_UPDATE_SERVICES = 1324;
export const ORDER_SERVICES_ALREADY_SELECTED = 1325;
export const ORDER_GENERATE_LABEL_DEALER_ADDRESS_CHECK_ERROR = 1334;
export const ORDER_GENERATE_LABEL_NOTARY_ADDRESS_CHECK_ERROR = 1335;
export const ORDER_GENERATE_LABEL_RATE_ERROR = 1336;