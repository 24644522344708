import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Row,
  CardHeader,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Button
} from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from 'context/access';
import CardPreloader from 'components/Shared/CardPreloader';
import classnames from "classnames";
import QRCode from "react-qr-code";
import shield from 'assets/images/shield-blue.svg';
import { resendCustomerNotifications, resetOrderActionFlag, startCustomerMeeting } from 'store/actions';
import Confirmation from 'components/Shared/Confirmation';
import { formatPhone, openInNewWindow, showError, showSuccess } from 'helpers/utilHelper';
import Order from 'model/order';

const ViewCustomer = props => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const { isRefreshing, order, isLocked } = props;

  // do not allow editing if order is still draft
  const isReadOnly = order.status == Order.STATUS_DRAFT;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // get redux state from the store
  const { resentCustomerNotif, isResendCustomerNotifInProgress, customerMeetingStarted, customerMeetingLink } = useSelector(state => state.Order.Single);

  // Check if the order type is remote
  const isRemoteOrder = () => order.customerLocation === Order.CUSTOMER_LOCATION_REMOTE;

  // is the confirmation dialog visible or not
  // used to show/hide the resend customer notifications warning
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  // runs whenever the 'resentCustomerNotif' flag changes
  // which happens after a resend cutomer notifications attempt
  useEffect(() => {
    if (resentCustomerNotif === true) {
      showSuccess(`Notifications have been resent`);
    } else if (resentCustomerNotif === false) {
      showError('Unable to resend customer notifications');
    }
    if (resentCustomerNotif !== null) {
      dispatch(resetOrderActionFlag('resentCustomerNotif'));
    }
  }, [resentCustomerNotif]);

  // runs whenever the 'customerMeetingStarted' flag changes
  // which happens after a start meeting attempt
  useEffect(() => {
    if (customerMeetingStarted === true) {
      openInNewWindow(customerMeetingLink);
    } else if (customerMeetingStarted === false) {
      showError('Unable to start meeting');
    }
    if (customerMeetingStarted !== null) {
      dispatch(resetOrderActionFlag('meetingStarted'));
    }
  }, [customerMeetingStarted]);

  // resend customer notifications event handler
  // make the remote call to resend customer notifications
  const sendCustomerNotifications = () => dispatch(resendCustomerNotifications(order.id));

  const startMeeting = () => dispatch(startCustomerMeeting(order.id));

  const getSmsConsentIcon = signer => {
    if (signer.givenSmsConsent === true) {
      return <>
        <i className="mdi mdi-cellphone-message ms-3 text-success" id="SmsConsentReceived" />
        <UncontrolledTooltip placement="top" target="SmsConsentReceived">Costumer Consent Received</UncontrolledTooltip>
      </>
    } else if (signer.givenSmsConsent === false) {
      return <>
        <i className="mdi mdi-cellphone-message-off ms-3 text-danger" id="SmsConsentDeclined" />
        <UncontrolledTooltip placement="top" target="SmsConsentDeclined">SMS Communication Declined</UncontrolledTooltip>
      </>
    }
  }

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Customer Information</div>
          </Col>
          {iAmGranted(perms.edit_orders) && !isLocked && !isReadOnly && <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => setIsConfirmationVisible(true)} disabled={isResendCustomerNotifInProgress}>Resend customer notifications</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>}
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody className="px-0 pb-0">
        {!!order.signers && order.signers.map((signer, idx) => <div key={signer.id} className={classnames('card-section', { 'blue': !!idx })}>
          <Table className={classnames('section-rows table', { 'bt-1': !idx, 'bb-0': !!idx || order.signers.length > 1 })}>
            <tbody>
              <tr>
                <td className="section-row-label">Name</td>
                <td className="section-row-value">{signer.fullName}</td>
              </tr>
              {/* Hide info if the order is In-store */}
              {isRemoteOrder() && <React.Fragment>
                <tr>
                  <td className="section-row-label">Email</td>
                  <td className="section-row-value text-break pe-4">{signer.email}</td>
                </tr>
                <tr>
                  <td className="section-row-label">Phone number</td>
                  <td className="section-row-value">{formatPhone(signer.phone)} {getSmsConsentIcon(signer)}</td>
                </tr>
                <tr>
                  <td className="section-row-label">Last 4 digits of SSN</td>
                  <td className="section-row-value">{!!signer.ssn ? signer.ssn : '--'}</td>
                </tr>
                {iAmGranted(perms.create_customer_calls) && idx === 0 && (
                  <tr>
                    <td className="section-row-label">Video Call</td>
                    <td className="section-row-value">
                      <Button color="primary" className="call-btn" onClick={startMeeting}>
                        Start Video Call <i className="bx bx-video" />
                      </Button>
                    </td>
                  </tr>
                )}
                {(idx == 0) && <React.Fragment>
                  <tr>
                    <td className="section-row-label">Address</td>
                    <td className="section-row-value">
                      {signer.address}
                      <a href={`https://maps.google.com/?q=${signer.latitude},${signer.longitude}`} target="_blank" rel="noreferrer"><i className="mdi mdi-map-marker ms-3"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td className="section-row-label">City</td>
                    <td className="section-row-value text-break pe-4">{signer.city}</td>
                  </tr>
                  <tr>
                    <td className="section-row-label">State or Province</td>
                    <td className="section-row-value">{signer.state}</td>
                  </tr>
                  <tr>
                    <td className="section-row-label">Zip code</td>
                    <td className="section-row-value text-break pe-4">{signer.zip}</td>
                  </tr>
                  {signer.address2 &&
                    <tr>
                      <td className="section-row-label">Additional Info</td>
                      <td className="section-row-value text-break pe-4">{signer.address2}</td>
                    </tr>
                  }
                </React.Fragment>}
                <tr>
                  <td className="section-row-label">Customer Link</td>
                  <td className="section-row-value">
                    <a href={signer.link} target="_blank" rel="noreferrer">{signer.link}</a>
                    <i className="mdi mdi-qrcode ms-3 cursor-pointer link-qr-icon">
                      <span className="link-qr-popup"><QRCode value={signer.link} /></span>
                    </i>
                  </td>
                </tr>
              </React.Fragment>}
            </tbody>
          </Table>
        </div>)}
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
    {isConfirmationVisible && <Confirmation
      confirmBtnText="Send"
      onConfirm={() => {
        setIsConfirmationVisible(false);
        sendCustomerNotifications();
      }}
      reverseButtons={false}
      onCancel={() => setIsConfirmationVisible(false)}>
      <span style={{ color: '#556EE6' }}>Are you sure you want to resend notifications to customer?</span>
    </Confirmation>}
  </React.Fragment>
}

ViewCustomer.propTypes = {
  isRefreshing: PropTypes.bool,
  order: PropTypes.object,
  isLocked: PropTypes.bool,
};

export default ViewCustomer;
