import React from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, FormText } from "reactstrap";
import { nullsToEmptyStrings } from "helpers/utilHelper";
import Order from "model/order";
import Select from "react-select";

const ShippingMethod = props => {

  const { defaultValues, tabId, nextHandler, prevHandler, order, deliveryRating } = props;

  /********** STATE **********/

  const validationSchema = {
    deliveryMethod: Yup.object().shape({
      serviceName: Yup.string().trim().required("Field is required"),
      serviceType: Yup.string().trim().required("Field is required"),
    }),
    packagingType: Yup.object().shape({
      packageName: Yup.string().trim().required("Field is required"),
      packageType: Yup.string().trim().required("Field is required"),
    }),
    additionalNotes: Yup.string().trim()
  }

  /********** FORM CONFIG **********/

  const formInitialValues = {
    // Dealer Details
    deliveryMethod: {
      serviceType: '',
      serviceName: ''
    },
    packagingType: {
      packageType: '',
      packageName: ''
    },
    additionalNotes: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      formik.setSubmitting(false);
      nextHandler(tabId, values);
    },
  });

  /********** EFFECTS **********/

  /********** EVENT HANDLERS **********/

  // handler that returns the deliver method options based on the rating
  // each delivery company has a different approach so we need to go
  // through both cases
  const getDeliveryMethodOptions = () => {
    if (order.shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
      const deliveryMethods = deliveryRating.rate
      const deliveryOptions = deliveryMethods.map((method) => {
        return {
          label: method.serviceName,
          value: {
            serviceName: method.serviceName,
            serviceType: method.serviceType
          }
        }
      })

      return deliveryOptions;
    } else {
      const deliveryMethods = deliveryRating.rate.RateResponse.RatedShipment
      const deliveryOptions = deliveryMethods.map((method) => {
        return {
          label: Order.getUpsServices()[method.Service.Code],
          value: {
            serviceType: method.Service.Code,
            serviceName: Order.getUpsServices()[method.Service.Code]
          }
        }
      })

      return deliveryOptions;
    }
  }

  const getPackagingTypeOptions = () => {
    if (order.shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
      const packagingTypes = Order.getFedexPackagingTypes();
      const packagingTypeOptions = Object.keys(packagingTypes).map((type) => {
        return {
          label: packagingTypes[type],
          value: {
            packageType: type,
            packageName: packagingTypes[type]
          }
        }
      })

      return packagingTypeOptions;
    } else {
      const packagingTypes = Order.getUpsPackagingTypes();
      const packagingTypeOptions = Object.keys(packagingTypes).map((type) => {
        return {
          label: packagingTypes[type],
          value: {
            packageType: type,
            packageName: packagingTypes[type]
          }
        }
      })

      return packagingTypeOptions;
    }
  }

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <Row>
      <Col>
        <Form>
          <div className="card-title pt-1 pb-3 mb-0">Shipping Information</div>
          <Row className="mb-4">
            <Col sm={6}>
              <Label className="col-sm-4 col-form-label">Chose A Delivery Method *</Label>
              <Select
                classNamePrefix="select2-selection"
                name="deliveryMethod"
                options={getDeliveryMethodOptions()}
                onChange={selected => formik.setFieldValue('deliveryMethod', selected.value)}
                onFocus={e => onFieldFocused(e, 'deliveryMethod')}
                value={getDeliveryMethodOptions().find(option => option.value.serviceType === formik.values.deliveryMethod.serviceType)}
                className={!!formik.errors.deliveryMethod && 'is-invalid'} />
              {!!formik.errors.deliveryMethod && <FormFeedback type="invalid">{formik.errors.deliveryMethod.serviceType}</FormFeedback>}
            </Col>
            <Col sm={6}>
              <Label className="col-sm-4 col-form-label">Packaging Type *</Label>
              <Select
                classNamePrefix="select2-selection"
                name="packagingType"
                options={getPackagingTypeOptions()}
                onChange={selected => formik.setFieldValue('packagingType', selected.value)}
                onFocus={e => onFieldFocused(e, 'packagingType')}
                value={getPackagingTypeOptions().find(option => option.value.packageType === formik.values.packagingType.packageType)}
                className={!!formik.errors.packagingType && 'is-invalid'} />
              {!!formik.errors.packagingType && <FormFeedback type="invalid">{formik.errors.packagingType.packageType}</FormFeedback>}
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="pb-3">
              <Label className="col-sm-4 col-form-label">Additional Notes To Receiver</Label>
              <Input type="textarea" className="form-control wizard-textarea" rows="3" maxLength="200" placeholder="Your message..." name="additionalNotes" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.additionalNotes} invalid={!!formik.errors.additionalNotes} />
              <div className="d-flex justify-content-end">
                <FormText type="valid">Max 200 characters</FormText>
              </div>
              {!!formik.errors.additionalNotes && <FormFeedback type="invalid">{formik.errors.additionalNotes}</FormFeedback>}
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Previous
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              <i className="mdi mdi-check me-1" />
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  </React.Fragment>
}

ShippingMethod.propTypes = {
  defaultValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  order: PropTypes.object,
  setDeliveryRating: PropTypes.func,
  deliveryRating: PropTypes.object
};

export default ShippingMethod;