import { useEffect, useCallback } from 'react';
import { useSocket } from 'context/socket';
import socketEvent, { socketEventResponse } from 'constants/socketEvent';
import httpCode from 'constants/httpCode';
import { AccessDeniedException, AuthException, BadRequestException, NotFoundException, ServerErrorException, UnprocessableEntityException, ValidationException } from 'helpers/errorHelper';

export const useSocketOn = (eventName, eventHandler, condition) => {

  const { socket } = useSocket();

  const isEnabled = useCallback(() => !condition || condition(), [condition]);

  useEffect(() => {
    if (isEnabled()) {
      socket.on(eventName, eventHandler);
    }
    return () => {
      if (isEnabled()) {
        socket.off(eventName, eventHandler);
      }
    }
  }, [eventName, eventHandler, isEnabled]);
}

export const useSubscribeToOrder = id => {

  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToOrder, { id });
    }
    return () => {
      if (socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromOrder, { id });
      }
    }
  }, [id, socket.id]);
}

export const useSubscribeToOrderDoc = id => {

  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToOrderDoc, { id });
    }
    return () => {
      if (socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromOrderDoc, { id });
      }
    }
  }, [id, socket.id]);
}

export const useSubscribeToOrderMessages = () => {

  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected) {
      socket.emit(socketEvent.subscribeToOrderMessages);
    }
    return () => {
      if (socket.connected) {
        socket.emit(socketEvent.unsubscribeFromOrderMessages);
      }
    }
  }, [socket.id]);
}

export const useSubscribeToUser = (id, condition, ack) => {

  const { socket } = useSocket();

  const isEnabled = useCallback(() => !condition || condition(), [condition]);

  useEffect(() => {
    if (isEnabled() && socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToUser, { id }, response => handleSocketEventResponse(response, ack));
    }
    return () => {
      if (isEnabled() && socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromUser, { id }, ack);
      }
    }
  }, [id, isEnabled, socket.id]);
}

export const useSubscribeToUsers = (ids, condition) => {

  const { socket } = useSocket();

  const isEnabled = useCallback(() => !condition || condition(), [condition]);

  useEffect(() => {
    if (isEnabled() && socket.connected && !!ids.length) {
      socket.emit(socketEvent.subscribeToUsers, { ids });
    }
    return () => {
      if (isEnabled() && socket.connected && !!ids.length) {
        socket.emit(socketEvent.unsubscribeFromUsers, { ids });
      }
    }
  }, [ids, isEnabled, socket.id]);
}

export const useSubscribeToOrderSigner = (id, condition) => {

  const { socket } = useSocket();

  const isEnabled = useCallback(() => !condition || condition(), [condition]);

  useEffect(() => {
    if (isEnabled() && socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToOrderSigner, { id });
    }
    return () => {
      if (isEnabled() && socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromOrderSigner, { id });
      }
    }
  }, [id, isEnabled, socket.id]);
}

export const useSubscribeToVidReq = id => {

  const { socket } = useSocket();

  useEffect(() => {
    if (socket.connected && !!id) {
      socket.emit(socketEvent.subscribeToVidReq, { id });
    }
    return () => {
      if (socket.connected && !!id) {
        socket.emit(socketEvent.unsubscribeFromVidReq, { id });
      }
    }
  }, [id, socket.id]);
}

const handleSocketEventResponse = (response, ack) => {
  if (response?.name.endsWith('Exception')) {
    response = buildResponseException(response);
    console.error(response);
  }
  ack?.(response);
}

const buildResponseException = response => {
  switch (response.status) {
    case httpCode.unauthorized:
      return new AuthException(null, response.code);
    case httpCode.forbidden:
      return new AccessDeniedException(null, response.code);
    case httpCode.notFound:
      return new NotFoundException(null, response.code);
    case httpCode.badRequest:
      if (response.fields) {
        return new ValidationException(null, response.fields);
      } else {
        return new BadRequestException(null, response.code);
      }
    case httpCode.unprocessableEntity:
      return new UnprocessableEntityException(null, response.code);
    case httpCode.internalServerError:
      return new ServerErrorException(null, response.code);
  }
}