import React from "react";
import PropTypes from 'prop-types';
import { useFormik } from "formik";
import { Row, Col, Button } from "reactstrap";
import { nullsToEmptyStrings } from "helpers/utilHelper";
import Order from "model/order";

const ShipmentSummary = props => {

  const { tabId, nextHandler, prevHandler, order, allValues, isSaveInProgress } = props;

  /********** FORM CONFIG **********/

  const formInitialValues = {
    ...nullsToEmptyStrings(allValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    onSubmit: values => {
      const destructuredValues = { ...values[0], ...values[1] }
      nextHandler(tabId, destructuredValues);
      formik.setSubmitting(false);
    },
  });

  return <React.Fragment>
    <div className="card-section px-0">
      <div className="d-flex">
        {
          order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING
            ?
            <>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Shipper Information</div>
                <p className="mb-1">Dealership name: <strong>{allValues[0].dealerStoreName}</strong></p>
                <p className="mb-1">Person submitting order: <strong>{allValues[0].dealerName}</strong></p>
                <p className="mb-1">Sender Address: <strong>{allValues[0].dealerAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].dealerCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].dealerState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].dealerZip}</strong></p>
              </div>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Ship To</div>
                <p className="mb-1">Notary name: <strong>{allValues[0].notaryFullName}</strong></p>
                <p className="mb-1">Notary Address: <strong>{allValues[0].notaryAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].notaryCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].notaryState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].notaryZip}</strong></p>
              </div>
            </>
            :
            <>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Shipper Information</div>
                <p className="mb-1">Notary name: <strong>{allValues[0].notaryFullName}</strong></p>
                <p className="mb-1">Notary Address: <strong>{allValues[0].notaryAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].notaryCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].notaryState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].notaryZip}</strong></p>
              </div>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Ship To</div>
                <p className="mb-1">Dealership name: <strong>{allValues[0].dealerStoreName}</strong></p>
                <p className="mb-1">Person submitting order: <strong>{allValues[0].dealerName}</strong></p>
                <p className="mb-1">Sender Address: <strong>{allValues[0].dealerAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].dealerCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].dealerState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].dealerZip}</strong></p>
              </div>
            </>
        }
        <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
          <div className="card-title mb-2">Shipping Information</div>
          {
            order.shippingCompany === Order.SHIPPING_COMPANY_FEDEX &&
            <>
              <p className="mb-1">Delivery method: <strong>{allValues[1].deliveryMethod?.serviceName}</strong></p>
              <p className="mb-1">Packaging Type: <strong>{Order.getFedexPackagingTypes()[allValues[1].packagingType?.packageType]}</strong></p>
            </>
          }
          {
            order.shippingCompany === Order.SHIPPING_COMPANY_UPS &&
            <>
              <p className="mb-1">Delivery method: <strong>{allValues[1].deliveryMethod?.serviceName}</strong></p>
              <p className="mb-1">Packaging Type: <strong>{Order.getUpsPackagingTypes()[allValues[1].packagingType?.packageType]}</strong></p>
            </>
          }
          <p className="mb-1">Additional Notes To Receiver: <strong>{!!allValues[1].additionalNotes ? allValues[1].additionalNotes : "--"}</strong></p>
        </div>
      </div>
      <div className="pb-4">
        <Row>
          <Col>
            <div className="text-end">
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
                <i className="mdi mdi-chevron-left me-1" />
                Previous
              </Button>
              <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={isSaveInProgress}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Generate Label
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </React.Fragment>
}

ShipmentSummary.propTypes = {
  defaultValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  order: PropTypes.object,
  setDeliveryRating: PropTypes.func,
  deliveryRating: PropTypes.object,
  allValues: PropTypes.object,
  isSaveInProgress: PropTypes.bool
};

export default ShipmentSummary;