let config = {};

const prefix = 'REACT_APP_';

for (const [key, value] of Object.entries(process.env)) {
  if (!key.startsWith(prefix)) {
    continue;
  }
  const confKey = key.replace(prefix, '');
  config[confKey] = value;
}

config = {
  ...config,
  APP_VERSION: '1.20.0',
  APP_TITLE: 'Maverick Signings',
  // number of rows to show in nested lists before displaying a 'View more' button
  COLLAPSED_TBL_ROWS: 6,
  // refresh cookie is available in paths prefixed with this value
  // must match backend value
  AUTH_PATH_PREFIX: '/auth',
  // socket connection requests are sent to this backend path
  // must match backend value
  SOCKET_PATH: '/be/socket.io/',
  // socket client will connect to this namespace by default
  // must be a valid backend namespace
  SOCKET_NAMESPACE: 'be',
  // vid request image names
  VID_REQ_IMAGE_FRONT: 'driver-license-front.jpg',
  VID_REQ_IMAGE_BACK: 'driver-license-back.jpg',
  VID_REQ_IMAGE_PASSPORT: 'passport.jpg',
  VID_REQ_IMAGE_SELFIE: 'selfie.jpg',
  VID_REQ_IMAGE_SIGNATURE: 'signature.jpg',
  // api urls
  API_BE_URL: config.API_URL + '/be',
  // delay after which the search query is sent
  DATA_TABLE_SEARCH_DELAY: 1000,
  // number of seconds after which an inactive user is considered idle
  USER_IDLE_DELAY: 180,
  // max number of images uploaded concurrently in chat
  CHAT_MAX_FILES: 3,
  // max accepted size (in bytes) of images uploaded in chat
  CHAT_MAX_FILE_SIZE: 5242880, // = 5 MB
  // price for the rush order service
  ORDER_RUSH_SERVICE_FEE: 100,
  // number of digits required for a valid phone number
  PHONE_NR_OF_DIGITS: 10,
  // terms and conditions hyperlink
  TERMS_AND_CONDITIONS_LINK: "https://mavsign.com/terms-and-conditions/",
  // max number of vehicles that can be added to an order
  ORDER_MAX_VEHICLES: 4,
};

export default config;