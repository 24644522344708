import React, { useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import { checkGenerateLabelError, getAddressComponents, nullsToEmptyStrings, showSuccess } from "helpers/utilHelper";
import Order from "model/order";
import PlacesAutocomplete from "components/Shared/PlacesAutocomplete";
import classnames from "classnames";
import { generateLabelGetRating } from "helpers/backendHelper";

/********** Forms **********/

const DealerForm = ({ formik, onFieldFocused }) => {

  const fillInAddress = () => place => {
    const addressComponents = getAddressComponents(place);
    formik.setFieldValue(`dealerZip`, addressComponents.zip);
    formik.setFieldValue(`dealerCity`, addressComponents.city);
    formik.setFieldValue(`dealerState`, addressComponents.state);
    formik.setFieldValue(`dealerAddress`, addressComponents.address);
  }

  const onAddressTextChanged = value => {
    // this function is called when the user types in the address field
    // which he should do only to search and not to write the actual address
    // addresses must be selected only from the autocomplete options
    // because only then we are able to extract the geo-coordinates
    // so here we invalidate any previous geo-coordinates as the user types
    // later, when he selects an address, the new geo-coordinates will be used
    formik.setFieldValue(`dealerAddress`, value);
  }

  return <Row className="mb-4">
    <Col sm={6} className="pb-3">
      <Label className="col-sm-4 col-form-label">Dealership Name *</Label>
      <Input type="text" className="form-control" placeholder="ex. Best Auto Store" name="dealerStoreName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.dealerStoreName} invalid={!!formik.errors.dealerStoreName} />
      {!!formik.errors.dealerStoreName && <FormFeedback type="invalid">{formik.errors.dealerStoreName}</FormFeedback>}
    </Col>
    <Col sm={6} className="pb-3">
      <Label className="col-sm-4 col-form-label">Person Submitting Order *</Label>
      <Input type="text" className="form-control" name="dealerName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.dealerName} invalid={!!formik.errors.dealerName} />
      {!!formik.errors.dealerName && <FormFeedback type="invalid">{formik.errors.dealerName}</FormFeedback>}
    </Col>
    <Col sm={6} className="pb-3">
      <Label className="col-sm-4 col-form-label">Dealership Address *</Label>
      <PlacesAutocomplete
        type="text"
        className={classnames("form-control", { "is-invalid": !!formik.errors.dealerAddress })}
        placeholder="ex. 2273 Muldrow Dr"
        name="dealerAddress"
        onChange={e => onAddressTextChanged(e.target.value)}
        onPlaceChanged={fillInAddress()}
        onFocus={onFieldFocused}
        value={formik.values.dealerAddress}
      />
      {!!formik.errors.dealerAddress && <FormFeedback type="invalid">{formik.errors.dealerAddress}</FormFeedback>}
    </Col>
    <Col sm={6} className="pb-3">
      <Label className="col-sm-4 col-form-label">City *</Label>
      <Input type="text" className="form-control" placeholder="ex. New York" name="dealerCity" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.dealerCity} invalid={!!formik.errors.dealerCity} />
      {!!formik.errors.dealerCity && <FormFeedback type="invalid">{formik.errors.dealerCity}</FormFeedback>}
    </Col>
    <Col sm={6}>
      <Label className="col-sm-4 col-form-label">State *</Label>
      <Input type="text" className="form-control" placeholder="ex. NY" name="dealerState" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.dealerState} invalid={!!formik.errors.dealerState} />
      {!!formik.errors.dealerState && <FormFeedback type="invalid">{formik.errors.dealerState}</FormFeedback>}
    </Col>
    <Col sm={6}>
      <Label className="col-sm-4 col-form-label">Zip *</Label>
      <Input type="text" className="form-control" placeholder="ex. 12345" name="dealerZip" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.dealerZip} invalid={!!formik.errors.dealerZip} />
      {!!formik.errors.dealerZip && <FormFeedback type="invalid">{formik.errors.dealerZip}</FormFeedback>}
    </Col>
  </Row>
}

const NotaryForm = ({ formik, onFieldFocused }) => {

  const fillInAddress = () => place => {
    const addressComponents = getAddressComponents(place);
    formik.setFieldValue(`notaryZip`, addressComponents.zip);
    formik.setFieldValue(`notaryCity`, addressComponents.city);
    formik.setFieldValue(`notaryState`, addressComponents.state);
    formik.setFieldValue(`notaryAddress`, addressComponents.address);
  }

  const onAddressTextChanged = value => {
    // this function is called when the user types in the address field
    // which he should do only to search and not to write the actual address
    // addresses must be selected only from the autocomplete options
    // because only then we are able to extract the geo-coordinates
    // so here we invalidate any previous geo-coordinates as the user types
    // later, when he selects an address, the new geo-coordinates will be used
    formik.setFieldValue(`notaryAddress`, value);
  }

  return <>
    <Row>
      <Col sm={6} className="pb-3">
        <Label className="col-sm-4 col-form-label">Notary Name *</Label>
        <Input type="text" className="form-control" name="notaryFullName" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryFullName} invalid={!!formik.errors.notaryFullName} />
        {!!formik.errors.notaryFullName && <FormFeedback type="invalid">{formik.errors.notaryFullName}</FormFeedback>}
      </Col>
    </Row>
    <Row className="mb-4">
      <Col sm={6} className="pb-3">
        <Label className="col-sm-4 col-form-label">Notary Address *</Label>
        <PlacesAutocomplete
          type="text"
          className={classnames("form-control", { "is-invalid": !!formik.errors.notaryAddress })}
          placeholder="ex. 2273 Muldrow Dr"
          name="notaryAddress"
          onChange={e => onAddressTextChanged(e.target.value)}
          onPlaceChanged={fillInAddress()}
          onFocus={onFieldFocused}
          value={formik.values.notaryAddress}
        />
        {!!formik.errors.notaryAddress && <FormFeedback type="invalid">{formik.errors.notaryAddress}</FormFeedback>}
      </Col>
      <Col sm={6} className="pb-3">
        <Label className="col-sm-4 col-form-label">City *</Label>
        <Input type="text" className="form-control" placeholder="ex. New York" name="notaryCity" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryCity} invalid={!!formik.errors.notaryCity} />
        {!!formik.errors.notaryCity && <FormFeedback type="invalid">{formik.errors.notaryCity}</FormFeedback>}
      </Col>
      <Col sm={6} className="pb-3">
        <Label className="col-sm-4 col-form-label">State *</Label>
        <Input type="text" className="form-control" placeholder="ex. NY" name="notaryState" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryState} invalid={!!formik.errors.notaryState} />
        {!!formik.errors.notaryState && <FormFeedback type="invalid">{formik.errors.notaryState}</FormFeedback>}
      </Col>
      <Col sm={6} className="pb-3">
        <Label className="col-sm-4 col-form-label">Zip *</Label>
        <Input type="text" className="form-control" placeholder="ex. 12345" name="notaryZip" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.notaryZip} invalid={!!formik.errors.notaryZip} />
        {!!formik.errors.notaryZip && <FormFeedback type="invalid">{formik.errors.notaryZip}</FormFeedback>}
      </Col>
    </Row>
  </>
}

const ShippingParties = props => {

  const { defaultValues, tabId, nextHandler, prevHandler, order, setDeliveryRating } = props;

  /********** STATE **********/

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const validationSchema = {
    dealerStoreName: Yup.string().trim().required('Field is required'),
    dealerName: Yup.string().trim().required('Field is required'),
    dealerAddress: Yup.string().trim().required('Field is required'),
    dealerCity: Yup.string().trim().required('Field is required'),
    dealerState: Yup.string().trim().required('Field is required'),
    dealerZip: Yup.string().trim().required('Field is required'),
    notaryFullName: Yup.string().trim().required('Field is required'),
    notaryAddress: Yup.string().trim().required('Field is required'),
    notaryCity: Yup.string().trim().required('Field is required'),
    notaryState: Yup.string().trim().required('Field is required'),
    notaryZip: Yup.string().trim().required('Field is required'),
  }

  /********** FORM CONFIG **********/

  const formInitialValues = {
    // Dealer Details
    dealerStoreName: order.dealerStoreName || "",
    dealerName: order.userFullName || "",
    dealerAddress: order.dealerStoreAddress || '',
    dealerCity: order.orderStoreCity || '',
    dealerState: order.dealerStoreState || '',
    dealerZip: order.dealerStoreZip || '',
    // Notary Details
    notaryFullName: order.notaries[0].notaryFullName || '',
    notaryAddress: order.notaries[0].notaryAddress || '',
    notaryCity: order.notaries[0].notaryCity || '',
    notaryState: order.notaries[0].notaryState || '',
    notaryZip: order.notaries[0].notaryZip || '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      getLabelRating(values);
    },
  });

  /********** EVENT HANDLERS **********/

  const getLabelRating = values => {
    setIsSaveInProgress(true);
    generateLabelGetRating(order.id, values)
      .then(response => {
        showSuccess('Addesses were validated and the rating was successfully received');
        setDeliveryRating(response)
        nextHandler(tabId, values);
      })
      .catch(ex => {
        checkGenerateLabelError(ex, order.shippingCompany)
      })
      .finally(() => {
        setIsSaveInProgress(false);
      })
  }

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <Row>
      <Col>
        <Form>
          <div className="card-title pt-1 pb-3 mb-0">Shipper Information</div>
          {order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING ? <DealerForm formik={formik} onFieldFocused={onFieldFocused} /> : <NotaryForm formik={formik} onFieldFocused={onFieldFocused} />}
          <div className="card-title pt-1 pb-3 mb-0">Ship To</div>
          {order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING ? <NotaryForm formik={formik} onFieldFocused={onFieldFocused} /> : <DealerForm formik={formik} onFieldFocused={onFieldFocused} />}
        </Form>
      </Col>
    </Row>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
              <i className="mdi mdi-chevron-left me-1" />
              Quit
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={isSaveInProgress}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  </React.Fragment>
}

ShippingParties.propTypes = {
  defaultValues: PropTypes.object,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  order: PropTypes.object,
  setDeliveryRating: PropTypes.func
};

DealerForm.propTypes = {
  formik: PropTypes.object,
  onFieldFocused: PropTypes.func
}

NotaryForm.propTypes = {
  formik: PropTypes.object,
  onFieldFocused: PropTypes.func
}

export default ShippingParties;