import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { Container, Row, Card, CardBody, Label, FormFeedback, Form, Button, Input, Alert } from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import Order from "model/order";
import { dictionaryToSelectOptions, getYesNoOptions, newLineTextFormat, showBriefError, phoneHasNoOfDigits, toSelectOptions } from "helpers/utilHelper";
import regx from "constants/regx";
import { useSubscription } from "context/subscription";
import { getDealerStore, getDealerStoreOrderCount, getDealerStores } from "helpers/backendHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import SpecialInstructionsLabel from "../Partial/View/SpecialInstructionsLabel";
import ModalAlert from "../../../components/Shared/ModalAlert";
import specialInstructionsIcon from "../../../assets/images/special-instructions-icon.svg";
import DealerGroup from "model/dealerGroup";
import phoneImage from "../../../assets/images/phone-design.svg";
import mavSimpleLogo from "../../../assets/images/mavsign-logo-blue-simple.svg";
import QRCode from "react-qr-code";
import Confirmation from "components/Shared/Confirmation";
import MarketingBanner from "pages/MarketingPages/Partial/Section/Banner";

const NewIndex = () => {

  // hooks that check permissions
  const { iAmGranted, iAmNotGranted, iAmDealerSalesManager } = useAccess();
  // router hook that helps redirect
  const navigate = useNavigate();
  // dealer store subscription info
  const { dealerStore, paymentPlan } = useSubscription();
  // read query string
  const [queryParams] = useSearchParams();
  // read order id from query param
  // it is optional so it can be empty
  const id = queryParams.get('id');

  /********** STATE **********/

  // whether the user is allowed to add new orders
  // NULL = undetermined
  const [canCreateOrders, setCanCreateOrders] = useState(null);
  // we use this as a way to force react to unmount and recreate a form field
  // this seems to be the only way to clear a select field
  const [nonce, setNonce] = useState(0);
  const [storeInfo, setStoreInfo] = useState(null);
  const [groupSpecialInstructionsVisible, setGroupSpecialInstructionsVisible] = useState(false);
  const [stores, setStores] = useState([]);

  const [isEsignNeededVisible, setIsEsignNeededVisible] = useState(false);

  // Split the text into separate lines
  let lines = storeInfo?.dealerGroupSpecialInstructions?.split('\n');

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the store data
    refreshStoreInfo();
  }, [dealerStore?.id]);

  /********** OTHER **********/

  const refreshStoreInfo = () => {
    getDealerStore(dealerStore?.id)
      .then(response => {
        setStoreInfo(response.dealerStore);
      })
      .catch(ex => { })
  };

  useEffect(() => {
    getDealerStoreList();
  }, [])


  const getDealerStoreList = () => {
    getDealerStores()
      .then(response => {
        setStores(response.dealerStores);
      })
      .catch(ex => {
        setStoresError(ex);
      });
  }

  /********** FORM CONFIG **********/

  // Sales Managers can create only VID orders
  const forcedContractInfoValue = iAmDealerSalesManager() && Order.CONTRACT_OPTION_VID;

  const formInitialValues = {
    dealerStoreId: '',
    customerLocation: '',
    contractInfo: forcedContractInfoValue || '',
    isEsignRequired: '',
    isInkSignRequired: '',
    phoneNo: '',
    isNotaryRequired: '',
    isRushRequired: '',
    isAoiThumbprintRequired: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      dealerStoreId: iAmGranted(perms.view_own_group_stores) ? Yup.string().required("Field is required") : Yup.string(),
      customerLocation: Yup.number().required('Field is required'),
      contractInfo: Yup.number()
        .when('customerLocation', {
          is: val => isRemote(val),
          then: Yup.number().required('Field is required'),
        })
        .test('anySignRequired', 'At least one signature type is required', (value, context) => {
          // do not validate if doc signing is not required or has not been selected yet
          if (!hasDocSigning(value) || context.parent.isEsignRequired === undefined || context.parent.isInkSignRequired === undefined) {
            return true;
          }
          return context.parent.isEsignRequired || context.parent.isInkSignRequired;
        }),
      isEsignRequired: Yup.number().when('contractInfo', {
        is: val => hasDocSigning(val),
        then: Yup.number().required('Field is required'),
      }),
      isInkSignRequired: Yup.number().when('contractInfo', {
        is: val => hasDocSigning(val),
        then: Yup.number().required('Field is required'),
      }),
      phoneNo: Yup.string().trim().when('customerLocation', {
        is: val => isInStore(val),
        then: Yup.string().trim().required('Field is required').matches(regx.phone, 'Invalid phone number').test('phone',
          'Field requires exactly 10 digits',
          ((value) => phoneHasNoOfDigits(value))
        ),
      }),
      isNotaryRequired: Yup.number().when('isInkSignRequired', {
        is: 1,
        then: Yup.number().required('Field is required'),
      }),
      isRushRequired: Yup.number().when('isNotaryRequired', {
        is: val => hasNotary(val),
        then: Yup.number().required('Field is required'),
      }),
      isAoiThumbprintRequired: Yup.number().when('isNotaryRequired', {
        is: val => hasNotary(val),
        then: Yup.number().required('Field is required'),
      }),
    }),
    onSubmit: values => {
      if (isRemote()) {
        // move to the next wizard step
        const nextStepParams = {
          cl: values.customerLocation,
          vi: hasVid() ? 1 : 0,
          es: values.isEsignRequired || 0,
          is: values.isInkSignRequired || 0,
          nt: values.isNotaryRequired || 0,
          ru: values.isRushRequired || 0,
          ds: iAmGranted(perms.view_own_group_stores) ? values.dealerStoreId : dealerStore.id,
          tp: values.isAoiThumbprintRequired || 0,
        };
        if (!!id) {
          navigate(route(routes.new_order_instructions, id, nextStepParams));
        } else {
          navigate(route(routes.new_order_instructions, 0, nextStepParams));
        }
      }
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // check if current payment plan is limited by the number of orders
    if (!paymentPlan?.maxOrders) {
      setCanCreateOrders(true);
      return;
    }
    // fetch the number of orders created so far by this store
    getDealerStoreOrderCount(dealerStore.id).then(response => {
      // decide whether this store can create any more orders
      setCanCreateOrders(response.num < paymentPlan.maxOrders);
    }).catch(ex => {
      showBriefError('Unable to determine number of orders');
      setCanCreateOrders(false);
    });
  }, []);

  // runs whenever customerLocation changes
  // resets dependent fields
  useEffect(() => {
    formik.setFieldValue('contractInfo', forcedContractInfoValue || '');
    formik.setFieldValue('phoneNo', '');
  }, [formik.values.customerLocation]);

  // runs whenever contractInfo changes
  // resets dependent fields
  useEffect(() => {
    formik.setFieldValue('isEsignRequired', '');
    formik.setFieldValue('isInkSignRequired', '')
    refreshForm();
  }, [formik.values.contractInfo]);

  // runs whenever isInkSignRequired changes
  // resets dependent fields
  useEffect(() => {
    if (!formik.values.isInkSignRequired) {
      // cleanup isNotaryRequired as it's not applicable to non-ink orders
      formik.setFieldValue('isNotaryRequired', '');
    }
    // reset select components
    refreshForm();
  }, [formik.values.isInkSignRequired]);

  // runs whenever isNotaryRequired changes
  // resets dependent fields
  useEffect(() => {
    // when isNotaryRequired switches to `No`, cleanup isRushRequired and isAoiThumbprintRequired
    if (!formik.values.isNotaryRequired) {
      formik.setFieldValue('isRushRequired', '');
      formik.setFieldValue('isAoiThumbprintRequired', '');
    }
  }, [formik.values.isNotaryRequired]);

  // Display the special instructions modal when land on the page
  useEffect(() => {
    setGroupSpecialInstructionsVisible(true);
  }, [])

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  /********** OTHER **********/

  const getCustomerLocationOptions = () => dictionaryToSelectOptions(Order.getCustomerLocationMap2());

  const getContractInfoOptions = () => dictionaryToSelectOptions(Order.getContractOptions());

  const getVidContractChoices = () => [Order.CONTRACT_OPTION_VID, Order.CONTRACT_OPTION_SIGNING_VID];

  const getSignContractChoices = () => [Order.CONTRACT_OPTION_SIGNING, Order.CONTRACT_OPTION_SIGNING_VID];

  const isRemote = customerLocation => (customerLocation || formik.values.customerLocation) == Order.CUSTOMER_LOCATION_REMOTE;

  const isInStore = customerLocation => (customerLocation || formik.values.customerLocation) == Order.CUSTOMER_LOCATION_IN_STORE;

  const hasDocSigning = contractInfo => getSignContractChoices().includes(parseInt(contractInfo || formik.values.contractInfo));

  const hasVid = contractInfo => getVidContractChoices().includes(parseInt(contractInfo || formik.values.contractInfo));

  const hasNotary = isNotaryRequired => !!isNotaryRequired || !!formik.values.isNotaryRequired;

  const canHaveNotary = () => formik.values.isInkSignRequired !== '' && !!formik.values.isInkSignRequired;

  const getRushOrderOptions = () => dictionaryToSelectOptions(Order.getRushOrderOptions());

  const getOrderAoiOptions = () => dictionaryToSelectOptions(Order.getAOIOptions());

  const getOrderEsignOptions = () => [
    {
      label: 'Yes, I will be uploading docs with required signature placement',
      value: 1
    },
    {
      label: 'No, I will be using my internal DMS System or electronic signatures are not needed',
      value: 0
    }
  ];

  const getOrderNotaryOptions = () => [
    {
      label: 'Yes, I need a MavSign Notary to meet with my customer to witness signatures',
      value: 1
    },
    {
      label: 'No, I do not need a MavSign Notary to witness signatures',
      value: 0
    }
  ];

  const isGroupSpecialInstructionsAppliesTo = () => storeInfo?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER);

  const showGroupSpecialInstructions = () => storeInfo?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  const hasEsignAndNotary = !!formik.values.isNotaryRequired && !!formik.values.isEsignRequired;

  // prepares the list of stores to be used as select options
  const getStoreOptions = () => {
    return [{ label: 'Choose', value: '' }, ...toSelectOptions(stores)];
  }

  const refreshForm = () => {
    // this is needed because the select control cannot be reset easily
    setNonce(n => n + 1);
  }

  // check if the order have on the same time e-sign service and notary service
  // and show a confirmation message based on this
  const goNext = () => {
    if (hasEsignAndNotary) return setIsEsignNeededVisible(true);
    formik.handleSubmit();
  };

  return <React.Fragment>
    {iAmGranted(perms.create_orders) && <>
      <div className="page-content">
        <MetaTitle>New order</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs()} />
            </Col>
            <Col xxl="4" className='text-end d-flex align-items-center justify-content-end mb-3 col-xxl-4'>
              {(storeInfo?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo()) && <SpecialInstructionsLabel instructions={storeInfo.dealerGroupSpecialInstructions} />}
            </Col>
          </Row>
          <MarketingBanner path="fedex" redirect={route(routes.view_fedex)} />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="card-title mb-1">Create new order</div>
                  <p>Basic information</p>
                  {canCreateOrders === null && <SpinnerChase className="mb-4" />}
                  {canCreateOrders === true && <Form className="pt-4">
                    {iAmGranted(perms.view_own_group_stores) && <>
                      <Row className="mb-4">
                        <Label className="col-sm-3 col-form-label">Select Store *</Label>
                        <Col sm={12}>
                          <Select
                            classNamePrefix="select2-selection"
                            name="dealerStoreId"
                            options={getStoreOptions()}
                            onChange={selected => formik.setFieldValue('dealerStoreId', selected.value)}
                            onFocus={e => onFieldFocused(e, 'dealerStoreId')}
                            value={getStoreOptions().find(option => option.value === formik.values.dealerStoreId)}
                            className={!!formik.errors.dealerStoreId && 'is-invalid'} />
                          {!!formik.errors.dealerStoreId && <FormFeedback type="invalid">{formik.errors.dealerStoreId}</FormFeedback>}
                        </Col>
                      </Row>
                    </>}
                    <Row>
                      <Col xl="6" className="mb-4">
                        <Label>My customer is: *</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          name="customerLocation"
                          options={getCustomerLocationOptions()}
                          onChange={selected => formik.setFieldValue('customerLocation', selected.value)}
                          onFocus={e => onFieldFocused(e, 'customerLocation')}
                          value={getCustomerLocationOptions().find(option => option.value === formik.values.customerLocation)}
                          className={!!formik.errors.customerLocation && 'is-invalid'} />
                        {!!formik.errors.customerLocation && <FormFeedback type="invalid">{formik.errors.customerLocation}</FormFeedback>}
                      </Col>
                      {isRemote() && <Col xl="6" className="mb-4">
                        <Label>My order should include: *</Label>
                        {forcedContractInfoValue && <Input type="text" className="form-control" value={Order.getContractOptions()[forcedContractInfoValue]} readOnly />}
                        {!forcedContractInfoValue && (
                          <Select
                            classNamePrefix="select2-selection"
                            name="contractInfo"
                            readOnly={iAmDealerSalesManager()}
                            options={getContractInfoOptions()}
                            onChange={selected => formik.setFieldValue('contractInfo', selected.value)}
                            onFocus={e => onFieldFocused(e, 'contractInfo')}
                            value={getContractInfoOptions().find(option => option.value === formik.values.contractInfo)}
                            className={!!formik.errors.contractInfo && 'is-invalid'} />
                        )}
                        {!!formik.errors.contractInfo && <FormFeedback type="invalid">{formik.errors.contractInfo}</FormFeedback>}
                      </Col>}
                      {hasDocSigning() && <Col xl="6" className="mb-4" key={'FormNewESign' + nonce}>
                        <Label>Will this order require e-signatures with Mavsign&apos;s NEW e-sign feature? *</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          name="isEsignRequired"
                          options={getOrderEsignOptions()}
                          onChange={selected => formik.setFieldValue('isEsignRequired', selected.value)}
                          onFocus={e => onFieldFocused(e, 'isEsignRequired')}
                          value={getOrderEsignOptions().find(option => option.value === formik.values.isEsignRequired)}
                          className={!!formik.errors.isEsignRequired && 'is-invalid'} />
                        {!!formik.errors.isEsignRequired && <FormFeedback type="invalid">{formik.errors.isEsignRequired}</FormFeedback>}
                      </Col>}
                      {formik.values.isEsignRequired !== '' && <Col xl="6" className="mb-4" key={'FormNewInkSign' + nonce}>
                        <Label>Will the customer need to wet-sign the Docs? *</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          name="isInkSignRequired"
                          options={getYesNoOptions()}
                          onChange={selected => formik.setFieldValue('isInkSignRequired', selected.value)}
                          onFocus={e => onFieldFocused(e, 'isInkSignRequired')}
                          value={getYesNoOptions().find(option => option.value === formik.values.isInkSignRequired)}
                          className={!!formik.errors.isInkSignRequired && 'is-invalid'} />
                        {!!formik.errors.isInkSignRequired && <FormFeedback type="invalid">{formik.errors.isInkSignRequired}</FormFeedback>}
                      </Col>}
                      {!!canHaveNotary() && <Col xl="6" className="mb-4" key={'FormNewNotary' + nonce}>
                        <Label>Do you need a Mavsign Notary to assist with this signing? *</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          name="isNotaryRequired"
                          options={getOrderNotaryOptions()}
                          onChange={selected => formik.setFieldValue('isNotaryRequired', selected.value)}
                          onFocus={e => onFieldFocused(e, 'isNotaryRequired')}
                          value={getOrderNotaryOptions().find(option => option.value === formik.values.isNotaryRequired)}
                          className={!!formik.errors.isNotaryRequired && 'is-invalid'} />
                        {!!formik.errors.isNotaryRequired && <FormFeedback type="invalid">{formik.errors.isNotaryRequired}</FormFeedback>}
                      </Col>}
                      {!!formik.values.isNotaryRequired && <Col xl="6" className="mb-4">
                        <Label>Should the notary take a fingerprint on the AOI? *</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          name="isAoiThumbprintRequired"
                          options={getOrderAoiOptions()}
                          onChange={selected => formik.setFieldValue('isAoiThumbprintRequired', selected.value)}
                          onFocus={e => onFieldFocused(e, 'isAoiThumbprintRequired')}
                          value={getOrderAoiOptions().find(option => option.value === formik.values.isAoiThumbprintRequired)}
                          className={!!formik.errors.isAoiThumbprintRequired && 'is-invalid'} />
                        {!!formik.errors.isAoiThumbprintRequired && <FormFeedback type="invalid">{formik.errors.isAoiThumbprintRequired}</FormFeedback>}
                      </Col>}
                      {!!formik.values.isNotaryRequired && <Col xl="6" className="mb-4">
                        <Label>Do you want the customer to sign in a RUSH turnaround time? *</Label>
                        <Select
                          classNamePrefix="select2-selection"
                          name="isRushRequired"
                          options={getRushOrderOptions()}
                          onChange={selected => formik.setFieldValue('isRushRequired', selected.value)}
                          onFocus={e => onFieldFocused(e, 'isRushRequired')}
                          value={getRushOrderOptions().find(option => option.value === formik.values.isRushRequired)}
                          className={!!formik.errors.isRushRequired && 'is-invalid'} />
                        {!!formik.errors.isRushRequired && <FormFeedback type="invalid">{formik.errors.isRushRequired}</FormFeedback>}
                      </Col>}
                    </Row>
                    {isInStore() && <Row>
                      <Col xl="6">
                        <p className="mb-2 font-weight-600 font-size-15">In order to continue with the order creation, please download<br />Mavsign Mobile app and create a In-store Verify ID order.</p>
                        <p>How to download the app</p>
                        <ul className="order-instructions">
                          <li><strong>Go to the app store on your phone</strong></li>
                          <li><strong>Search for Mavsign</strong></li>
                          <li><strong>Install the app and use your mavsign.com credentials to access your account</strong></li>
                        </ul>
                        <p className="font-weight-600 font-size-15">Or scan the QR code below</p>
                      </Col>
                      <Row>
                        <Col xl="6">
                          <div className="qr-code-wrapper">
                            <div className="qr-image">
                              <img className="phone-image" src={phoneImage} />
                              <img className='mav-logo-simple' src={mavSimpleLogo} />
                              <div className='qr-parent'><QRCode size={120} value={"https://apps.apple.com/us/app/mavsign-2-0-instore-idcheck/id6479606763"} /></div>
                            </div>
                            <div className="helper-text">
                              <div className="qr-helper-line">1. Scan the QR code</div>
                              <div className="qr-helper-line">2. Download the new mavsign app</div>
                              <div className="qr-helper-line">3. Login using your dealer credentials</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Row>}
                    <Row className="mb-2">
                      <Col className="text-end pt-4">
                        <Link to={route(routes.list_orders)} className="btn btn-primary btn-faded">Cancel</Link>
                        <Button type="button" color="primary" className="ms-2" onClick={() => goNext()} disabled={formik.isSubmitting || isInStore()}>Next</Button>
                      </Col>
                    </Row>
                  </Form>}
                  {canCreateOrders === false && <Alert color="danger" className="fade show text-center">
                    <i className="mdi mdi-alert-circle-outline me-2"></i>
                    You have reached the maximum number of orders for this type of subscription.
                    <Link to={route(routes.setup_subscription_plan)} className="ms-2">Click here to upgrade</Link>
                  </Alert>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Group Special Instructions Modal */}
      {(groupSpecialInstructionsVisible && showGroupSpecialInstructions()) && <ModalAlert
        customClass="special-instruction"
        style={{ paddingTop: 30, paddingBottom: 30, minWidth: 650 }}
        title="Dealership Special Instructions"
        confirmBtnText="Consent"
        onConfirm={() => setGroupSpecialInstructionsVisible(false)}
        closeOnClickOutside={false}
      >
        <div className="my-3 mx-4">
          <div className="d-flex justify-content-center mb-4">
            <div className="font-weight-600">Please do not forget these important points while submitting your order</div>
          </div>
          <div className="d-flex align-items-start modal-alert-container">
            <img className='me-4' src={specialInstructionsIcon} />
            <div>{newLineTextFormat(lines).map((line, index) => <p className='mb-1' key={index}>{line}</p>)}</div>
          </div>
        </div>
      </ModalAlert>}

      {/* Esign Needed Modal */}
      {isEsignNeededVisible && <Confirmation
        confirmBtnText="Confirm"
        onConfirm={() => {
          formik.handleSubmit();
          setIsEsignNeededVisible(false);
        }}
        style={{ backgroundColor: '#fff' }}
        onCancel={() => setIsEsignNeededVisible(false)}
        cancelBtnText="Cancel"
        reverseButtons={false}
        closeOnClickOutside={false}
      >
        <p>Both <span className="font-weight-700">Electronic Signature</span> and <span className="font-weight-700">Notary service</span> were included in this order.</p>
        <p><span className="font-weight-700">The Electronic Signature service </span>requires uploading the desired documents, adding the places where the documents need signatures and then the customer electronically signing the documents from their device.</p>
        <p><span className="font-weight-700">The Notary service</span> implies sending the desired documents to the notary, the notary meeting with the customer in person to wet sign the documents and then sending the signed documents back to the dealership.</p>
        <p>Are you sure you need both these services on this order?<br />
          Select Confirm to move forward with the order creation process or Cancel to adjust the services selected
        </p>
      </Confirmation>
      }
    </>}
    {iAmNotGranted(perms.create_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'ORDERS',
  url: route(routes.list_orders),
}, {
  title: 'New order',
}];

export default NewIndex;
