import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Label, Input, FormFeedback, Button } from "reactstrap";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik, FormikProvider, FieldArray } from "formik";
import Col from "components/Shared/Col";
import { doOrderFormCleanup, doOrderSingleCleanup, updateOrderVehicle } from "store/actions";
import { getOrderSteps, nullsToEmptyStrings, showBriefSuccess, showError } from "helpers/utilHelper";
import { ValidationException } from "helpers/errorHelper";
import { route } from "helpers/routeHelper";

const FormNewVehicle = props => {

  const { id, defaultValues, navRoutes, stepChanged, currentStep } = props;

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  // get redux state from the store
  const { order, isSaveInProgress, saved, saveError } = useSelector(state => state.Order.Form);
  const [nextRoute, setNextRoute] = useState('');

  const steps = getOrderSteps(defaultValues);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    vehicles: [],
    ...nullsToEmptyStrings(defaultValues, true),
  };

  const vehicleValidationSchema = {
    stockNum: Yup.string().trim().required('Field is required'),
    vin: Yup.string()
      .transform(value => (value ? value.replace(/\s+/g, '') : ''))
      .required('Field is required'),
    yearMakeModel: Yup.string().trim(),
  };

  const formValidationSchema = {
    vehicles: Yup.array().of(
      Yup.object().shape(vehicleValidationSchema)
    ).min(1, 'Please add at least one vehicle'),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => {
      dispatch(updateOrderVehicle(values, id))
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderFormCleanup());
    }
  }, []);

  // runs once when default values are set
  useEffect(() => {
    if (!defaultValues.vehicles?.length) {
      // this order does NOT have saved vehicles so add a default empty row
      formik.setFieldValue('vehicles', [getEmptyVehicle()]);
    }
  }, [defaultValues]);

  // runs whenever the 'saved' flag changes
  // which happens after a save-order attempt
  useEffect(() => {
    if (saved === true) {
      showBriefSuccess('Vehicle information has been saved');
      // multiple wizard steps use the same redux store
      // however the redirect to the next step happens before the previous component unmounts
      // which leads to the store cleanup not being done by the time the next step reads the store
      // so here we do the cleanup before the redirect
      dispatch(doOrderFormCleanup());
      dispatch(doOrderSingleCleanup());
      navigate(route(nextRoute, order.id));
    } else if (saved === false) {
      showError('Unable to save order');
      // see if the save failed due to validation
      if (saveError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  // runs whenever the 'stepChanged' flag changes
  // which happens after a step was selected from the FormSteps(wizard navigation) component
  useEffect(() => {
    // step selected from the FormSteps(wizard navigation) component
    const selectedStepIndex = stepChanged.stepIndex;

    // Get the step index for the active page from steps array based on the currentStep flag (name of the page)
    const stepIndex = steps.findIndex(step => step.key === currentStep);

    if (selectedStepIndex < stepIndex) {
      handlerGoPrevAvailable(selectedStepIndex);
    } else if (stepIndex + 1 === selectedStepIndex) {
      handlerGoNext();
    }
  }, [stepChanged]);

  /********** EVENT HANDLERS **********/

  // on change event handler that converts user input to uppercase letters
  const convertToUpperCaseOnChange = event => {
    const { name, id } = event.target;
    formik.setFieldValue(name || id, event.target.value.toUpperCase());
  };

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  // focus event handler
  // used to clear field errors
  const onArrayFieldFocused = (arrayName, index, fieldName) => {
    const errors = formik.errors;
    if (errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][fieldName]) {
      delete errors[arrayName][index][fieldName];
      formik.setStatus(errors);
    }
  }

  const addRow = arrayHelpers => {
    onFieldFocused(null, 'vehicles');
    arrayHelpers.push(getEmptyVehicle());
  }

  const handlerGoPrevAvailable = (selectedStepIndex) => {
    if (!formik.isSubmitting && !!navRoutes?.availablePrevs) {
      setNextRoute(navRoutes.availablePrevs[selectedStepIndex].route);
      formik.handleSubmit();
    }
  }

  const handlerGoPrev = () => {
    if (!formik.isSubmitting) {
      setNextRoute(navRoutes.prev);
      formik.handleSubmit();
    }
  }

  const handlerGoNext = () => {
    if (!formik.isSubmitting) {
      setNextRoute(navRoutes.next);
      formik.handleSubmit();
    }
  }

  /********** OTHER **********/

  // returns an empty vehicle object
  const getEmptyVehicle = () => ({
    stockNum: '',
    vin: '',
    yearMakeModel: '',
  });

  return <React.Fragment>
    <Form className="pt-4">
      <FormikProvider value={formik}>
        <FieldArray name="vehicles" render={arrayHelpers => <React.Fragment>
          {formik.values.vehicles.map((vehicle, index) => <div className={classnames('card-section', { 'blue pt-3 pb-1 mt-1': index > 0 })} key={index}>
            <Row>
              <Col xl="6" className="mb-4">
                <Label>Stock Number *</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`vehicles.${index}.stockNum`}
                  onChange={convertToUpperCaseOnChange}
                  onFocus={() => onArrayFieldFocused('vehicles', index, 'stockNum')}
                  value={formik.values.vehicles[index].stockNum}
                  invalid={!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.stockNum}
                />
                {!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.stockNum && <FormFeedback type="invalid">{formik.errors.vehicles[index].stockNum}</FormFeedback>}
              </Col>
              <Col xl="6" className="mb-4">
                <Label>V.I.N. # *</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`vehicles.${index}.vin`}
                  onChange={convertToUpperCaseOnChange}
                  onFocus={() => onArrayFieldFocused('vehicles', index, 'vin')}
                  value={formik.values.vehicles[index].vin}
                  invalid={!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.vin}
                />
                {!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.vin && <FormFeedback type="invalid">{formik.errors.vehicles[index].vin}</FormFeedback>}
              </Col>
            </Row>
            <Row>
              <Col xl="6" className="mb-4">
                <Label>Vehicle year, make, model</Label>
                <Input
                  type="text"
                  className="form-control"
                  name={`vehicles.${index}.yearMakeModel`}
                  onChange={formik.handleChange}
                  onFocus={() => onArrayFieldFocused('vehicles', index, 'yearMakeModel')}
                  value={formik.values.vehicles[index].yearMakeModel}
                  invalid={!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.yearMakeModel}
                />
                {!!formik.errors.vehicles && !!formik.errors.vehicles[index]?.yearMakeModel && <FormFeedback type="invalid">{formik.errors.vehicles[index].yearMakeModel}</FormFeedback>}
              </Col>
              {!!index && <Col xl="6" className="d-flex justify-content-end align-items-end">
                <Button type="button" color="link" onClick={() => arrayHelpers.remove(index)}>Remove</Button>
              </Col>}
            </Row>
          </div>)}
          {!!formik.errors.vehicles && typeof formik.errors.vehicles === 'string' && <div className="card-section">
            <FormFeedback type="invalid" className="d-block">{formik.errors.vehicles}</FormFeedback>
          </div>}
          <div className="card-section">
            <Row className="mb-2">
              <Col className="pt-4">
                <Button type="button" color="primary" onClick={() => addRow(arrayHelpers)}>+ Additional Vehicles</Button>
              </Col>
              <Col className="text-end pt-4">
                {navRoutes.prev &&
                  <Button type="button" color="primary" className="ms-2 btn-faded" onClick={handlerGoPrev} disabled={formik.isSubmitting}>
                    {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                    Previous
                  </Button>}
                <Button type="button" color="primary" className="ms-2" onClick={handlerGoNext} disabled={formik.isSubmitting}>
                  {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </React.Fragment>} />
      </FormikProvider>
    </Form>
  </React.Fragment>
}

FormNewVehicle.propTypes = {
  currentStep: PropTypes.string.isRequired,
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  navRoutes: PropTypes.object,
  stepChanged: PropTypes.object
};

export default FormNewVehicle;