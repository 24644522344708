import React from "react";
import { Badge } from "reactstrap";

const NewBadge = () => {
  return (
    <span>
      <Badge color="danger" pill>
        New
      </Badge>
    </span>
  )
};

export default NewBadge;