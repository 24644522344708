import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Form, Button, Table, Alert, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Col from "components/Shared/Col";
import { doOrderDocNormListCleanup, doOrderFormCleanup, doOrderSingleCleanup, getOrderDocNormList, updateOrderDocs } from "store/actions";
import { getOrderSteps, nullsToEmptyStrings, showBriefSuccess, showError } from "helpers/utilHelper";
import { ORDER_DOC_CONFLICTING_WITH_DELIVERY_OPTION, UnprocessableEntityException, ValidationException } from "helpers/errorHelper";
import { route } from "helpers/routeHelper";
import FormNewDocumentsFile from "./File";
import SpinnerChase from "components/Shared/SpinnerChase";
import FormNewDocumentsDoc from "./Doc";

const FormNewDocuments = props => {

  const { id, defaultValues, navRoutes, stepChanged, currentStep } = props;
  const ref = useRef(null);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();

  /********** STATE **********/

  // get redux state from the store
  const { order, isSaveInProgress, saved, saveError } = useSelector(state => state.Order.Form);
  const orderDocIds = useSelector(state => state.OrderDoc.NormList.orderDocIds);
  const orderDocsError = useSelector(state => state.OrderDoc.NormList.orderDocsError);
  const isLoadInProgress = useSelector(state => state.OrderDoc.NormList.isLoadInProgress);

  // we use this as a way to force react to unmount and recreate the upload form
  // this seems to be the only way to clear the select and file upload controls
  const [nonce, setNonce] = useState(0);
  const [nextRoute, setNextRoute] = useState('');

  const steps = getOrderSteps(defaultValues);

  /********** FORM CONFIG **********/

  const formInitialValues = {
    docs: 0,
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      docs: Yup.number().required('Field is required').min(1, 'Please add some documents'),
    }),
    onSubmit: values => {
      if (ref.current && ref.current.formikValues.file) {
        return ref.current.formikInstance.submitForm();
      }
      dispatch(updateOrderDocs(values, id));
    },
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    refreshOrderDocs();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderFormCleanup());
      dispatch(doOrderDocNormListCleanup());
    }
  }, []);

  // we populate the 'docs' form field with the number of uploaded docs
  // so we can later show an error if the user did not upload any docs
  useEffect(() => {
    formik.setFieldValue('docs', orderDocIds.length);
  }, [orderDocIds]);

  // runs whenever the 'saved' flag changes
  // which happens after a save-order attempt
  useEffect(() => {
    if (saved === true) {
      showBriefSuccess('Document information has been saved');
      // multiple wizard steps use the same redux store
      // however the redirect to the next step happens before the previous component unmounts
      // which leads to the store cleanup not being done by the time the next step reads the store
      // so here we do the cleanup before the redirect
      dispatch(doOrderFormCleanup());
      dispatch(doOrderSingleCleanup());
      navigate(route(nextRoute, order.id));
    } else if (saved === false) {
      let errMessage = 'Unable to save order';
      if (saveError instanceof UnprocessableEntityException) {
        if (saveError.code == ORDER_DOC_CONFLICTING_WITH_DELIVERY_OPTION) {
          errMessage = 'Some documents are incompatible with the selected delivery option and need to be removed or re-uploaded';
        }
        // see if the save failed due to validation
      } else if (saveError instanceof ValidationException) {
        // show an error on each invalid field
        for (const [name, message] of Object.entries(saveError.fields)) {
          formik.setFieldError(name, message);
        }
      }
      showError(errMessage);
      // enable the save button
      formik.setSubmitting(false);
    }
  }, [saved]);

  // runs whenever the 'stepChanged' flag changes
  // which happens after a step was selected from the FormSteps(wizard navigation) component
  useEffect(() => {
    // step selected from the FormSteps(wizard navigation) component
    const selectedStepIndex = stepChanged.stepIndex;

    // Get the step index for the active page from steps array based on the currentStep flag (name of the page)
    const stepIndex = steps.findIndex(step => step.key === currentStep);

    if (selectedStepIndex < stepIndex) {
      handlerGoPrevAvailable(selectedStepIndex);
    } else if (stepIndex + 1 === selectedStepIndex) {
      handlerGoNext();
    }
  }, [stepChanged]);

  /********** OTHER **********/

  const handlerGoPrevAvailable = (selectedStepIndex) => {
    if (!formik.isSubmitting && !!navRoutes?.availablePrevs) {
      setNextRoute(navRoutes.availablePrevs[selectedStepIndex].route);
      formik.handleSubmit();
    }
  }

  const handlerGoPrev = () => {
    if (!formik.isSubmitting) {
      setNextRoute(navRoutes.prev);
      formik.handleSubmit();
    }
  }

  const handlerGoNext = () => {
    if (!formik.isSubmitting) {
      setNextRoute(navRoutes.next);
      formik.handleSubmit();
    }
  }

  const refreshOrderDocs = () => dispatch(getOrderDocNormList(id));

  const uploadSuccessHandler = () => {
    // reset upload form fields
    // this is needed because the select and file upload controls cannot be reset easily
    setNonce(n => n + 1);
    // refresh list of documents
    refreshOrderDocs();
  }

  return <React.Fragment>
    <div className="card-section pt-4">
      <FormNewDocumentsFile
        ref={ref}
        key={nonce}
        id={id}
        finishedHandler={uploadSuccessHandler}
        eSignEnabled={defaultValues.isEsignRequired}
        inkSignEnabled={defaultValues.isInkSignRequired}
        hasNotary={defaultValues.isNotaryRequired}
        docDeliveryOption={defaultValues.docDeliveryOption} />
      {!!formik.errors.docs && <FormFeedback type="invalid" className="d-block mb-2">{formik.errors.docs}</FormFeedback>}
      <Row className="mb-4">
        <Col>
          <Table className="table new-order-uploaded-docs mb-0 bb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Document name</th>
                <th>Document type</th>
                <th>Requires Notarization</th>
                <th>No of pages</th>
                <th>No of signatures</th>
                <th>No of initials</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoadInProgress && <tr>
                <td colSpan="7">
                  <SpinnerChase className="sm mt-2 mb-2" />
                </td>
              </tr>}
              {orderDocsError && <tr>
                <td colSpan="7"><Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load documents
                </Alert>
                </td>
              </tr>}
              {!isLoadInProgress && !orderDocsError && !orderDocIds.length && <tr>
                <td colSpan="7" className="text-center">No documents uploaded</td>
              </tr>}
              {!isLoadInProgress && !orderDocsError && orderDocIds.map((docId, index) => <FormNewDocumentsDoc
                key={docId}
                id={docId}
                num={index + 1}
                refreshListHandler={refreshOrderDocs}
                deliveryOption={defaultValues.docDeliveryOption} />)}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Form>
        <Row className="mb-2">
          <Col className="text-end pt-4">
            {navRoutes.prev && <Button type="button" color="primary" className="ms-2 btn-faded" onClick={handlerGoPrev} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Previous
            </Button>}
            <Button type="button" color="primary" className="ms-2" onClick={handlerGoNext} disabled={formik.isSubmitting} >
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  </React.Fragment>
}

FormNewDocuments.propTypes = {
  currentStep: PropTypes.string.isRequired,
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  navRoutes: PropTypes.object,
  stepChanged: PropTypes.object
};

export default FormNewDocuments;