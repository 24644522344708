import Message from 'model/message';
import VidRequest from 'model/vidRequest';
import { combineReducers } from 'redux';
import {
  GET_ORDER_DT,
  GET_ORDER_DT_OK,
  GET_ORDER_DT_ERR,
  DO_ORDER_DT_CLEANUP,

  APPLY_ORDER_DT_FILTERS,
  PATCH_ORDER_DT_FILTERS,
  CLEAR_ORDER_DT_FILTERS,
  UNSET_ORDER_DT_FILTERS,
  DO_ORDER_DT_FILTERS_CLEANUP,

  GET_IN_PROGRESS_ORDER_LIST,
  GET_ORDER_LIST_OK,
  GET_ORDER_LIST_ERR,
  DO_ORDER_LIST_CLEANUP,

  GET_ORDER,
  GET_ORDER_WITH_CUSTOMER_SIGNERS,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  GET_ORDER_WITH_VEHICLES,
  GET_FULL_ORDER,
  GET_ORDER_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK,
  GET_ORDER_WITH_VEHICLES_OK,
  GET_FULL_ORDER_OK,
  GET_ORDER_ERR,
  PATCH_ORDER,
  SEAL_ORDER,
  SEAL_ORDER_OK,
  SEAL_ORDER_ERR,
  RESEND_CUSTOMER_NOTIFICATIONS,
  RESEND_CUSTOMER_NOTIFICATIONS_OK,
  RESEND_CUSTOMER_NOTIFICATIONS_ERR,
  START_CUSTOMER_MEETING,
  START_CUSTOMER_MEETING_OK,
  START_CUSTOMER_MEETING_ERR,
  START_SCHEDULER_MEETING,
  START_SCHEDULER_MEETING_OK,
  START_SCHEDULER_MEETING_ERR,
  DO_ORDER_SINGLE_CLEANUP,
  RESET_ORDER_ACTION_FLAG,

  CREATE_ORDER,
  UPDATE_ORDER_SERVICES,
  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_VEHICLE,
  UPDATE_ORDER_CONTRACT,
  UPDATE_ORDER_DOCS,
  SUBMIT_ORDER,
  SAVE_ORDER_OK,
  SAVE_ORDER_ERR,
  DO_ORDER_FORM_CLEANUP,

  GET_ORDER_VID,
  GET_ORDER_VID_OK,
  GET_ORDER_VID_ERR,
  DO_ORDER_VID_CLEANUP,

  GET_ORDER_INK_SIGN_DOCS,
  GET_ORDER_INK_SIGN_DOCS_OK,
  GET_ORDER_INK_SIGN_DOCS_ERR,
  DO_ORDER_INK_SIGN_DOCS_CLEANUP,

  GET_ORDER_E_SIGN_DOCS,
  GET_ORDER_E_SIGN_DOCS_OK,
  GET_ORDER_E_SIGN_DOCS_ERR,
  DO_ORDER_E_SIGN_DOCS_CLEANUP,

  GET_ORDER_ACTIVITY,
  GET_ORDER_ACTIVITY_OK,
  GET_ORDER_ACTIVITY_ERR,
  DO_ORDER_ACTIVITY_CLEANUP,

  GET_ORDER_MESSAGES,
  GET_ORDER_MESSAGES_OK,
  DO_ORDER_MESSAGES_CLEANUP,
  GET_ORDER_MESSAGES_ERR,
  GET_LAST_ORDER_MESSAGE,
  GET_LAST_ORDER_MESSAGE_OK,
  GET_LAST_ORDER_MESSAGE_ERR,
  GET_CHAT_MEMBERS,
  GET_CHAT_MEMBERS_OK,
  GET_CHAT_MEMBERS_ERR,
  GET_ORDER_SUPPORTING_DOCS,
  GET_ORDER_SUPPORTING_DOCS_OK,
  GET_ORDER_SUPPORTING_DOCS_ERR,
  DO_ORDER_SUPPORTING_DOCS_CLEANUP,
  GET_ORDER_PREVIEW_DOCS,
  GET_ORDER_PREVIEW_DOCS_OK,
  GET_ORDER_PREVIEW_DOCS_ERR,
  DO_ORDER_PREVIEW_DOCS_CLEANUP,
  RESET_CHANNEL_UNREAD_MESSAGES,
} from './actionTypes';

/********** DATATABLE **********/

const defaultDtState = {
  orders: [],
  ordersError: null,
  totalCount: 0,
  listParams: {
    sortBy: 'id',
    sortDir: 'desc',
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
  },
  isLoadInProgress: false,
};

const Dt = (state = defaultDtState, action) => {
  switch (action.type) {
    case GET_ORDER_DT:
      state = {
        ...state,
        listParams: action.payload.params,
        ordersError: null,
        isLoadInProgress: !!action.payload.silentLoad ? false : true,
      };
      break
    case GET_ORDER_DT_OK:
      state = {
        ...state,
        orders: action.payload.response.orders,
        totalCount: action.payload.response.totalCount,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_DT_ERR:
      state = {
        ...state,
        orders: [],
        totalCount: 0,
        ordersError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    case DO_ORDER_DT_CLEANUP:
      state = {
        ...state,
        orders: [],
        ordersError: null,
      };
      break
  }
  return state;
}

/********** DATATABLE FILTERS **********/

const defaultDtFiltersState = {
  id: '',
  customerLocation: '',
  schedulerId: '',
  status: [],
  statusMode: "INC",
  service: [],
  serviceMode: "INC",
  serviceOperator: "AND",
  incAlert: [],
  alertOperator: "AND",
  vidStatuses: [],
  _set: false,
};

const DtFilters = (state = defaultDtFiltersState, action) => {
  switch (action.type) {
    case APPLY_ORDER_DT_FILTERS:
      state = {
        ...defaultDtFiltersState, // we need to initialize `includeStatus` and `includeService` with empty arrays
        ...action.payload.filters,
        _set: true,
      };
      break
    case PATCH_ORDER_DT_FILTERS:
      state = {
        ...state,
        ...action.payload.filters,
        _set: true,
      };
      break
    case CLEAR_ORDER_DT_FILTERS:
      state = {
        ...defaultDtFiltersState,
        _set: true,
      };
      break
    case UNSET_ORDER_DT_FILTERS:
      state = {
        ...state,
        _set: false,
      };
      break
    case DO_ORDER_DT_FILTERS_CLEANUP:
      state = {
        ...defaultDtFiltersState,
      };
      break
  }
  return state;
}

/********** LIST **********/

const defaultListState = {
  orders: [],
  ordersError: null,
  isLoadInProgress: false,
}

const List = (state = defaultListState, action) => {
  switch (action.type) {
    case GET_IN_PROGRESS_ORDER_LIST:
      state = {
        ...state,
        isLoadInProgress: true,
        ordersError: null,
      };
      break;
    case GET_ORDER_LIST_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        orders: action.payload.response.orders,
      };
      break;
    case GET_ORDER_LIST_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        ordersError: action.payload.error,
      };
      break;
    case DO_ORDER_LIST_CLEANUP:
      state = { ...defaultListState };
      break;
  }
  return state;
}

/********** SINGLE **********/

const defaultSingleState = {
  order: null,
  orderError: null,
  isLoadInProgress: false,
  deleted: null,
  deleteError: null,
  isDeleteInProgress: false,
  cancelled: null,
  cancelError: null,
  isCancelInProgress: false,
  sealed: null,
  sealError: null,
  isSealInProgress: false,
  resentCustomerNotif: null,
  resendCustomerNotifError: null,
  isResendCustomerNotifInProgress: false,
  reloaded: null,
  reloadError: null,
  isReloadInProgress: false,
  customerMeetingStarted: null,
  customerMeetingStartError: false,
  isCustomerMeetingStartInProgress: false,
  customerMeetingLink: null,
  schedulerMeetingStarted: null,
  schedulerMeetingStartError: false,
  isSchedulerMeetingStartInProgress: false,
  schedulerMeetingLink: null,
};

const Single = (state = defaultSingleState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER:
    case GET_ORDER_WITH_CUSTOMER_SIGNERS:
    case GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES:
    case GET_ORDER_WITH_VEHICLES:
    case GET_FULL_ORDER:
      state = {
        ...state,
        orderError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_OK:
      state = {
        ...state,
        order: action.payload.response.order,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_WITH_CUSTOMER_SIGNERS_OK:
      state = {
        ...state,
        order: {
          ...action.payload.orderResponse.order,
          signers: action.payload.signersResponse.signers,
        },
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK:
      state = {
        ...state,
        order: {
          ...action.payload.orderResponse.order,
          signers: action.payload.signersResponse.signers,
          notaries: action.payload.notariesResponse.notaries,
        },
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_WITH_VEHICLES_OK:
      state = {
        ...state,
        order: {
          ...action.payload.orderResponse.order,
          vehicles: action.payload.vehiclesResponse.vehicles,
        },
        isLoadInProgress: false,
      };
      break
    case GET_FULL_ORDER_OK:
      state = {
        ...state,
        order: {
          ...action.payload.orderResponse.order,
          signers: action.payload.signersResponse.signers,
          vehicles: action.payload.vehiclesResponse.vehicles,
          notaries: action.payload.notariesResponse.notaries,
        },
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_ERR:
      state = {
        ...state,
        order: null,
        orderError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // patch
    case PATCH_ORDER:
      state = {
        ...state,
        order: {
          ...state.order,
          ...action.payload.data,
        },
      };
      break
    // seal
    case SEAL_ORDER:
      state = {
        ...state,
        sealed: null,
        sealError: null,
        isSealInProgress: true,
      };
      break
    case SEAL_ORDER_OK:
      state = {
        ...state,
        sealed: true,
        isSealInProgress: false,
      };
      break
    case SEAL_ORDER_ERR:
      state = {
        ...state,
        sealed: false,
        sealError: action.payload.error,
        isSealInProgress: false,
      };
      break
    // resend customer notifications
    case RESEND_CUSTOMER_NOTIFICATIONS:
      state = {
        ...state,
        resentCustomerNotif: null,
        resendCustomerNotifError: null,
        isResendCustomerNotifInProgress: true,
      };
      break
    case RESEND_CUSTOMER_NOTIFICATIONS_OK:
      state = {
        ...state,
        resentCustomerNotif: true,
        isResendCustomerNotifInProgress: false,
      };
      break
    case RESEND_CUSTOMER_NOTIFICATIONS_ERR:
      state = {
        ...state,
        resentCustomerNotif: false,
        resendCustomerNotifError: action.payload.error,
        isResendCustomerNotifInProgress: false,
      };
      break
    // meeting
    case START_CUSTOMER_MEETING:
      state = {
        ...state,
        customerMeetingStarted: null,
        customerMeetingStartError: null,
        isCustomerMeetingStartInProgress: true,
        customerMeetingLink: null,
      };
      break
    case START_CUSTOMER_MEETING_OK:
      state = {
        ...state,
        customerMeetingStarted: true,
        isCustomerMeetingStartInProgress: false,
        customerMeetingLink: action.payload.response.link,
      };
      break
    case START_CUSTOMER_MEETING_ERR:
      state = {
        ...state,
        customerMeetingStarted: false,
        customerMeetingStartError: action.payload.error,
        isCustomerMeetingStartInProgress: false,
      };
      break
    case START_SCHEDULER_MEETING:
      state = {
        ...state,
        schedulerMeetingStarted: null,
        schedulerMeetingStartError: null,
        isSchedulerMeetingStartInProgress: true,
        schedulerMeetingLink: null,
      };
      break
    case START_SCHEDULER_MEETING_OK:
      state = {
        ...state,
        schedulerMeetingStarted: true,
        isSchedulerMeetingStartInProgress: false,
        schedulerMeetingLink: action.payload.response.link,
      };
      break
    case START_SCHEDULER_MEETING_ERR:
      state = {
        ...state,
        schedulerMeetingStarted: false,
        schedulerMeetingStartError: action.payload.error,
        isSchedulerMeetingStartInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_SINGLE_CLEANUP:
      state = { ...defaultSingleState };
      break
    case RESET_ORDER_ACTION_FLAG:
      if (!state.hasOwnProperty(action.payload.flag)) {
        break;
      }
      state = {
        ...state,
        [action.payload.flag]: null,
      };
      break
  }
  return state;
}

/********** FORM **********/

const defaultFormState = {
  order: null,
  saved: null,
  saveError: null,
  isSaveInProgress: false,
};

const Form = (state = defaultFormState, action) => {
  switch (action.type) {
    case CREATE_ORDER:
      state = {
        ...state,
        order: action.payload.data,
        saved: null,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case UPDATE_ORDER_SERVICES:
    case UPDATE_ORDER_CUSTOMER:
    case UPDATE_ORDER_VEHICLE:
    case UPDATE_ORDER_CONTRACT:
    case UPDATE_ORDER_DOCS:
      state = {
        ...state,
        order: action.payload.data,
        saved: null,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case SUBMIT_ORDER:
      state = {
        ...state,
        saved: null,
        saveError: null,
        isSaveInProgress: true,
      };
      break
    case SAVE_ORDER_OK:
      state = {
        ...state,
        order: {
          ...state.order,
          id: action.payload.response.id,
        },
        saved: true,
        isSaveInProgress: false,
      };
      break
    case SAVE_ORDER_ERR:
      state = {
        ...state,
        saved: false,
        saveError: action.payload.error,
        isSaveInProgress: false,
      };
      break
    case DO_ORDER_FORM_CLEANUP:
      state = { ...defaultFormState };
      break
  }
  return state;
}

/********** VID **********/

const defaultVidState = {
  [VidRequest.SIGNER_FIRST]: {
    vidRequest: null,
    vidRequestError: null,
    isLoadInProgress: false,
  },
  [VidRequest.SIGNER_SECOND]: {
    vidRequest: null,
    vidRequestError: null,
    isLoadInProgress: false,
  },
};

const Vid = (state = defaultVidState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_VID:
      state = {
        ...state,
        [action.payload.signer]: {
          ...state[action.payload.signer],
          vidRequestError: null,
          isLoadInProgress: true,
        },
      };
      break
    case GET_ORDER_VID_OK:
      state = {
        ...state,
        [action.payload.signer]: {
          ...state[action.payload.signer],
          vidRequest: action.payload.response.vidRequest,
          isLoadInProgress: false,
        },
      };
      break
    case GET_ORDER_VID_ERR:
      state = {
        ...state,
        [action.payload.signer]: {
          ...state[action.payload.signer],
          vidRequest: null,
          vidRequestError: action.payload.error,
          isLoadInProgress: false,
        },
      };
      break
    // cleanup
    case DO_ORDER_VID_CLEANUP:
      state = {
        ...state,
        [action.payload.signer]: defaultVidState[action.payload.signer],
      };
      break
  }
  return state;
}

/********** INK-SIGN DOCS **********/

const defaultInkSignDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const InkSignDocs = (state = defaultInkSignDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_INK_SIGN_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_INK_SIGN_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_INK_SIGN_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_INK_SIGN_DOCS_CLEANUP:
      state = { ...defaultInkSignDocsState };
      break
  }
  return state;
}

/********** E-SIGN DOCS **********/

const defaultESignDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const ESignDocs = (state = defaultESignDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_E_SIGN_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_E_SIGN_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_E_SIGN_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_E_SIGN_DOCS_CLEANUP:
      state = { ...defaultESignDocsState };
      break
  }
  return state;
}

/********** SUPPORTING DOCS **********/

const defaultSupportingDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const SupportingDocs = (state = defaultSupportingDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_SUPPORTING_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_SUPPORTING_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_SUPPORTING_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_SUPPORTING_DOCS_CLEANUP:
      state = { ...defaultSupportingDocsState };
      break
  }
  return state;
}

const defaultPreviewDocsState = {
  docs: null,
  docsError: null,
  isLoadInProgress: false,
};

const PreviewDocs = (state = defaultPreviewDocsState, action) => {
  switch (action.type) {
    // load
    case GET_ORDER_PREVIEW_DOCS:
      state = {
        ...state,
        docsError: null,
        isLoadInProgress: true,
      };
      break
    case GET_ORDER_PREVIEW_DOCS_OK:
      state = {
        ...state,
        docs: action.payload.response.orderDocs,
        isLoadInProgress: false,
      };
      break
    case GET_ORDER_PREVIEW_DOCS_ERR:
      state = {
        ...state,
        docs: null,
        docsError: action.payload.error,
        isLoadInProgress: false,
      };
      break
    // cleanup
    case DO_ORDER_PREVIEW_DOCS_CLEANUP:
      state = { ...defaultPreviewDocsState };
      break
  }
  return state;
}

/********** ACTIVITY **********/

const defaultActivityState = {
  logs: [],
  logsError: null,
  isLoadInProgress: false,
}

const Activity = (state = defaultActivityState, action) => {
  switch (action.type) {
    case GET_ORDER_ACTIVITY:
      state = {
        ...state,
        logsError: null,
        isLoadInProgress: true,
      };
      break;
    case GET_ORDER_ACTIVITY_OK:
      state = {
        ...state,
        logs: action.payload.response.activityLogs,
        isLoadInProgress: false,
      };
      break;
    case GET_ORDER_ACTIVITY_ERR:
      state = {
        ...state,
        logsError: action.payload.error,
        isLoadInProgress: false,
      };
      break;
    case DO_ORDER_ACTIVITY_CLEANUP:
      state = { ...defaultActivityState };
      break;
  }
  return state;
}

/********** MESSAGES **********/

const channels = Object.keys(Message.getChannelMap());
// build a map that contains all the channels as keys and empty arrays as values
// these represent the initial values for each channel messages list and will be populated with data once the backend request returns
const channelsMap = channels.reduce((accum, val) => {
  accum[val] = [];
  return accum;
}, {});

const defaultMessagesState = {
  messages: {
    ...channelsMap
  },
  unreadMessagesCount: {
    ...channelsMap
  },
  messagesError: null,
  isLoadInProgress: false,
  lastMessage: null,
  isLoadMessageInProgress: false,
  lastMessageError: null,
  members: {
    ...channelsMap
  },
  membersError: null,
  isLoadMembersInProgress: false,
}

const Messages = (state = defaultMessagesState, action) => {
  switch (action.type) {
    case GET_ORDER_MESSAGES:
      state = {
        ...state,
        isLoadInProgress: true,
        messagesError: null,
      };
      break
    case GET_ORDER_MESSAGES_OK:
      state = {
        ...state,
        isLoadInProgress: false,
        messages: {
          ...state.messages,
          [action.payload.channelId]: action.payload.response.messages
        },
        unreadMessagesCount: {
          ...state.unreadMessagesCount,
          [action.payload.channelId]: action.payload.response.unreadMessagesCount
        }
      };
      break
    case GET_ORDER_MESSAGES_ERR:
      state = {
        ...state,
        isLoadInProgress: false,
        messagesError: action.payload.error
      };
      break
    case DO_ORDER_MESSAGES_CLEANUP:
      state = { ...defaultMessagesState }
      break
    case GET_LAST_ORDER_MESSAGE:
      state = {
        ...state,
        lastMessage: null,
        isLoadMessageInProgress: true,
        lastMessageError: null,
      };
      break
    case GET_LAST_ORDER_MESSAGE_OK:
      state = {
        ...state,
        isLoadMessageInProgress: false,
        lastMessage: action.payload.response,
      };
      break
    case GET_LAST_ORDER_MESSAGE_ERR:
      state = {
        ...state,
        isLoadMessageInProgress: false,
        lastMessage: null,
        lastMessageError: action.payload.error,
      };
      break
    case GET_CHAT_MEMBERS:
      state = {
        ...state,
        isLoadMembersInProgress: true,
        membersError: null,
      };
      break
    case GET_CHAT_MEMBERS_OK:
      state = {
        ...state,
        isLoadMembersInProgress: false,
        members: {
          ...state.members,
          [action.payload.channelId]: action.payload.response.participants,
        }
      }
      break
    case GET_CHAT_MEMBERS_ERR:
      state = {
        ...state,
        isLoadMembersInProgress: false,
        membersError: action.payload.error
      }
      break
    case RESET_CHANNEL_UNREAD_MESSAGES:
      state = {
        ...state,
        unreadMessagesCount: {
          ...state.unreadMessagesCount,
          [action.payload.channelId]: 0
        }
      }
      break
  }
  return state;
}


export default combineReducers({
  Dt,
  DtFilters,
  List,
  Single,
  Form,
  Vid,
  InkSignDocs,
  ESignDocs,
  Activity,
  Messages,
  SupportingDocs,
  PreviewDocs
});