import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row, Table
} from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import PropTypes from "prop-types";
import Order from 'model/order';
import { getOrderReturn, getOrderShipping } from "helpers/backendHelper";
import TrackingModal from "components/Shared/TrackingModal";
import { formatDate, formats } from "helpers/dateHelper";
import shield from "assets/images/shield-blue.svg";
import { Link } from 'react-router-dom';
import { route, routes } from 'helpers/routeHelper';
import { getBeUrl } from 'helpers/utilHelper';

// receives shipping data and transforms it into an array of objects containing date, description and address
const normalizeTrackingData = (shippingCompany, data) => {
  if (shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
    const events = data.map(event => {
      const address = [];
      const { city, stateOrProvinceCode, postalCode } = event.scanLocation;
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateOrProvinceCode) address.push(stateOrProvinceCode);
      if (postalCode) address.push(postalCode);
      // format the date that is of type ISO string
      const date = formatDate(event.date, formats.TRACKING_DATE);
      return {
        date,
        description: event.eventDescription,
        address,
      }
    })
    return events;
  }
  if (shippingCompany === Order.SHIPPING_COMPANY_UPS) {
    const events = data.map(event => {
      const address = [];
      const { city, stateProvince, postalCode } = event.location?.address || {};
      // build the address based on the available information
      // since the response may not contain all the address details
      if (city) address.push(city);
      if (stateProvince) address.push(stateProvince);
      if (postalCode) address.push(postalCode);
      // format the date that is of type YYYYMMDD
      const date = formatDate(event.date, formats.MEDIUM_DATE);
      return {
        date,
        description: event.status?.description,
        address,
      }
    })
    return events;
  }
  return data;
}

const ViewTrackingAndShipping = ({ isRefreshing, toggleEditMode, order, isLocked }) => {
  // hooks that check permissions
  const { iAmGranted } = useAccess();

  // do not allow editing if order is still draft
  const isReadOnly = order.status == Order.STATUS_DRAFT;

  /********** STATE **********/
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);

  // Data for modals
  const [shippingData, setShippingData] = useState(null);
  const [shippingDataError, setShippingDataError] = useState(false);
  const [returnData, setReturnData] = useState(null);
  const [returnDataError, setReturnDataError] = useState(false);

  /********** EFFECTS **********/
  // Shipping
  useEffect(() => {
    if (order.shippingPackageAwb) {
      getShippingData();
    }
  }, [order.shippingPackageAwb]);

  // Return
  useEffect(() => {
    if (order.returnPackageAwb) {
      getReturnData();
    }
  }, [order.returnPackageAwb]);

  /********** EVENTS **********/
  const toggleShippingModal = () => setIsShippingModalOpen(!isShippingModalOpen);

  const toggleReturnModal = () => setIsReturnModalOpen(!isReturnModalOpen);

  /********** OTHER **********/
  const getShippingData = () => {
    getOrderShipping(order.id)
      .then(response => {
        // the shipping response object is different for UPS/Fedex, we need to bring it to a standard form
        const normalizedData = normalizeTrackingData(order.shippingCompany, response.response.events);
        setShippingData(normalizedData);
      })
      .catch(err => setShippingDataError(true));
  }

  const getReturnData = () => {
    getOrderReturn(order.id)
      .then(response => {
        // the return response object is different for UPS/Fedex, we need to bring it to a standard form
        const normalizedData = normalizeTrackingData(order.shippingCompany, response.response.events);
        setReturnData(normalizedData);
      })
      .catch(err => setReturnDataError(true));
  }

  const getInfoMessageText = () => order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING ? "Labels will be generated when a notary is assigned. A copy of the labels will be emailed to the dealership contact as well as available here." : "A Return label is being generated and will be displayed above when ready"

  return <React.Fragment>
    <Card className="expand-v overflow-hidden">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Tracking and Labels</div>
          </Col>
          {iAmGranted(perms.edit_order_shipping, order) && !isReadOnly && !isLocked && !order.hasMavShippingLabel && <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={toggleEditMode}>Edit</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>}
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="section-rows table bt-1 bb-0">
          <tbody>
            <tr>
              <td className="section-row-label">Shipping Company</td>
              <td className="section-row-value">{Order.getShippingCompanyName(order.shippingCompany) || '--'}</td>
            </tr>
            {
              order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING &&
              <>
                <tr>
                  <td className="section-row-label">Shipping Package</td>
                  <td className="section-row-value">
                    {order.shippingPackageAwb ? <span className='package-number cursor-pointer' onClick={() => setIsShippingModalOpen(true)}>
                      {order.shippingPackageAwb}</span> : '--'}
                  </td>
                </tr>
                {
                  order.hasMavShippingLabel &&
                  <tr>
                    <td className="section-row-label">Outgoing Label</td>
                    <td className="section-row-value">
                      {
                        !!order.outgoingShippingLabel
                          ?
                          <a href={getBeUrl(`order/${order.id}/outgoing-label/download`)} className='d-inline-block mt-2' target="_blank" rel="noreferrer">Print Outgoing Notary Label</a>
                          :
                          "--"
                      }
                    </td>
                  </tr>
                }
              </>
            }
            <tr>
              <td className="section-row-label">Return Package</td>
              <td className="section-row-value">
                {order.returnPackageAwb ? <span className='package-number cursor-pointer' onClick={() => setIsReturnModalOpen(true)}>
                  {order.returnPackageAwb}</span> : '--'}
              </td>
            </tr>
            <tr>
              <td className="section-row-label">Return label</td>
              <td className="section-row-value">
                {
                  !!order.returnShippingLabel
                    ?
                    <a href={getBeUrl(`order/${order.id}/return-label/download`)} className='d-inline-block mt-2' target="_blank" rel="noreferrer">{!!order?.hasMavShippingLabel ? 'Print Dealer Return Label' : order.returnShippingLabel}</a>
                    :
                    "--"
                }
              </td>
            </tr>
            {
              order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING && order.hasMavShippingLabel &&
              <>
                <tr>
                  <td className="section-row-label">Document Pickup</td>
                  <td className="section-row-value">
                    {!!order.mavShippingLabelPickup ? Order.getGenerateLabelDocumentsPickup()[order.mavShippingLabelPickup].firstText : "--"}
                  </td>
                </tr>
                {
                  order.mavShippingLabelPickup === Order.MAV_SHIPPING_PICKUP_MAV_SCHEDULED &&
                  <tr>
                    <td className="section-row-label">Pickup Information</td>
                    <td className="section-row-value">
                      {!!order.docsSentPickupInfo ? order.docsSentPickupInfo : "--"}
                    </td>
                  </tr>
                }
              </>
            }
          </tbody>
        </Table>
        {
          (order.mavShippingLabelStatus === Order.MAV_SHIPPING_LABEL_STATUS_NEW || order.mavShippingLabelStatus === Order.MAV_SHIPPING_LABEL_STATUS_CANCEL) &&
          <div className='d-flex align-items-center'>
            <Card className="text-start d-inline-block outgoing-label-card m-0 w-100">
              <CardBody>
                <Row>
                  <Col xs="auto" className="d-flex align-items-center font-size-24">
                    <i className='bx bxs-info-circle info-icon-blue'></i>
                  </Col>
                  <Col className="d-flex align-items-center font-size-12">{getInfoMessageText()}</Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        }
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>

    {/* Shipping Modal */}
    <TrackingModal
      isOpen={isShippingModalOpen}
      toggle={toggleShippingModal}
      modalData={shippingData}
      modalDataError={shippingDataError}
      closeModal={() => setIsShippingModalOpen(false)}
      trackingNumber={order?.shippingPackageAwb}
    />
    {/* Return Modal */}
    <TrackingModal
      isOpen={isReturnModalOpen}
      toggle={toggleReturnModal}
      modalData={returnData}
      modalDataError={returnDataError}
      closeModal={() => setIsReturnModalOpen(false)}
      trackingNumber={order?.returnPackageAwb}
    />
  </React.Fragment>
}

ViewTrackingAndShipping.propTypes = {
  isRefreshing: PropTypes.bool,
  isLocked: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  order: PropTypes.object,
}

export default ViewTrackingAndShipping;