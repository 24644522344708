import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import Col from "components/Shared/Col";
import { doOrderSingleCleanup, getOrderWithVehicles } from "store/actions";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import FormSteps from "pages/Order/Partial/Form/Steps";
import Order from "model/order";
import FormNewVehicle from "pages/Order/Partial/Form/New/Vehicle";
import SpecialInstructionsLabel from "../Partial/View/SpecialInstructionsLabel";
import DealerGroup from "model/dealerGroup";
import MarketingBanner from "pages/MarketingPages/Partial/Section/Banner";

const NewVehicle = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  // read route params
  let { id } = useParams();
  // params are strings by default however we need ID as in int
  id = parseInt(id);

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({ next: null, prev: null });

  // check if the step from FormSteps component was clicked
  const [isStepSelected, setIsStepSelected] = useState({});

  const handlerStepChanged = (data) => setIsStepSelected(data);


  // check if the group special instructions exist and applies to dealers
  const isGroupSpecialInstructionsAppliesTo = () => order?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER);
  const showGroupSpecialInstructions = () => order?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // fetch the order data so we can prepopulate the form fields
    dispatch(getOrderWithVehicles(id));
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  // runs whenever the order changes
  // which is when the order is fetched from remote
  useEffect(() => {
    if (order && order.status != Order.STATUS_DRAFT) {
      // editing an order via this wizard is only allowed while the order is in DRAFT mode
      // (order is not yet submitted and the user is navigating back and forth through the wizard)
      // after that editing must be done from the view-order screen
      navigate(route(routes.view_order, order.id));
    }
  }, [order]);

  return <React.Fragment>
    {iAmGranted(perms.create_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>New order - Vehicle information</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs()} />
            </Col>
            <Col xxl="4" className='text-end d-flex align-items-center justify-content-end mb-3 col-xxl-4'>
              {showGroupSpecialInstructions() && <SpecialInstructionsLabel instructions={order.dealerGroupSpecialInstructions} />}
            </Col>
          </Row>
          <MarketingBanner path="fedex" redirect={route(routes.view_fedex)} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title pt-1 pb-4 mb-0">Create new order</div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <FormSteps currentStep={Order.ORDER_CREATION_STEP_VEHICLE} order={order} setNavRoutes={setNavRoutes} onChange={handlerStepChanged} />
                  <FormNewVehicle id={id} currentStep={Order.ORDER_CREATION_STEP_VEHICLE} defaultValues={order} navRoutes={navRoutes} stepChanged={isStepSelected} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {isLoadInProgress && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.create_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = () => [{
  title: 'ORDERS',
  url: route(routes.list_orders),
}, {
  title: 'New order',
  url: route(routes.new_order),
}, {
  title: 'Vehicle information',
}];

export default NewVehicle;