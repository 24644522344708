import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardHeader, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Col from "components/Shared/Col";
import { dictionaryToSelectOptions, nullsToEmptyStrings, showBriefError, showError, showSuccess, getBeUrl } from "helpers/utilHelper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateOrderTrackingAndShipping } from "helpers/backendHelper";
import { ValidationException } from "helpers/errorHelper";
import Order from 'model/order';

const FormEditTrackingAndShipping = ({ defaultValues, finishedHandler, id }) => {
  /********** STATE **********/
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  /********** FORM CONFIG **********/

  const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

  const formInitialValues = {
    shippingCompany: '',
    shippingPackageAwb: '',
    returnPackageAwb: '',
    returnShippingLabel: '',
    ...nullsToEmptyStrings(defaultValues),
  };

  const formValidationSchema = {
    returnShippingLabel: Yup.mixed().test('fileType', 'File must be a pdf or an image', (value) => {
      if (!value || !isNaN(formik.values.returnShippingLabel)) return true; // Allow if no file is selected or is a TS value
      return value || allowedFileTypes.includes(value.type);
    }).when(['docDeliveryOption', 'hasMavShippingLabel'], {
      is: (docDeliveryOption, hasMavShippingLabel) => docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD && !hasMavShippingLabel,
      then: Yup.string().nullable().required('Field is required'),
    }),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(formValidationSchema),
    onSubmit: values => {
      // Check if a PDF file has been selected
      if (formik.values.returnShippingLabel instanceof File) {
        const formData = new FormData();

        formData.append("returnShippingLabel", values.returnShippingLabel);
        formData.append("shippingCompany", values.shippingCompany);
        formData.append("shippingPackageAwb", values.shippingPackageAwb);
        formData.append("returnPackageAwb", values.returnPackageAwb);

        saveTrackingAndShipping(formData, id)
      } else {
        saveTrackingAndShipping(values, id)
      }
    },
  })

  /********** EFFECTS **********/

  // runs whenever the validation fails
  useEffect(() => {
    if (!formik.isValid) {
      showBriefError('Form has errors');
    }
  }, [formik.isValid]);

  /********** EVENT HANDLERS **********/

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }
  /********** OTHER **********/

  const saveTrackingAndShipping = (values, id) => {
    setIsSaveInProgress(true);
    updateOrderTrackingAndShipping(values, id)
      .then(response => {
        showSuccess('Tracking and Labels has been saved');
        finishedHandler(true);
      })
      .catch(ex => {
        showError('Unable to save Tracking and Labels');
        // see if the save failed due to validation
        if (ex instanceof ValidationException) {
          // show an error on each invalid field
          for (const [name, message] of Object.entries(ex.fields)) {
            formik.setFieldError(name, message);
          }
        }
        // enable the save button
        formik.setSubmitting(false);
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  }

  const getShippingCompanyOptions = () => {
    const options = dictionaryToSelectOptions(Order.getShippingCompanyMap());
    if (!defaultValues.hasMavShippingLabel) {
      const otherCompanyOption = options.find(option => option.value === Order.SHIPPING_COMPANY_OTHER);
      // reorder the options by keeping `Other` as the last element
      return [...options.filter(option => option.value !== Order.SHIPPING_COMPANY_OTHER), otherCompanyOption];
    } else {
      return [...options.filter(option => option.value !== Order.SHIPPING_COMPANY_OTHER)];
    }
  };

  const isReturnLabelRequired = defaultValues.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD;

  return <React.Fragment>
    <Card className="expand-v">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Tracking and Labels</div>
          </Col>
          <Col xs="auto" className="text-end">
            <Button type="button" color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
              {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
              {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
              Save
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={() => finishedHandler()}>
              <i className="mdi mdi-chevron-left me-1" />Cancel
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        <Form className="pt-4">
          <div className="card-section">
            <Row className='mb-4'>
              <Col xl="6">
                <Label>Shipping Company</Label>
                <Select
                  classNamePrefix="select2-selection"
                  name="shippingCompany"
                  options={getShippingCompanyOptions()}
                  onChange={selected => formik.setFieldValue("shippingCompany", selected.value)}
                  onFocus={e => onFieldFocused(e, "shippingCompany")}
                  value={getShippingCompanyOptions().find(option => option.value === formik.values.shippingCompany)}
                  className={!!formik.errors.shippingCompany && "is-invalid"}
                />
                {!!formik.errors.shippingCompany && <FormFeedback type="invalid">{formik.errors.shippingCompany}</FormFeedback>}
              </Col>
            </Row>
            {
              defaultValues.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING &&
              <>
                <Row className="mb-4">
                  <Col xl="6">
                    <Label>Shipping Package</Label>
                    <Input type="text" className="form-control" name="shippingPackageAwb" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.shippingPackageAwb} invalid={!!formik.errors.shippingPackageAwb} />
                    {!!formik.errors.shippingPackageAwb && <FormFeedback type="invalid">{formik.errors.shippingPackageAwb}</FormFeedback>}
                  </Col>
                </Row>
              </>
            }
            <Row className="mb-4">
              <Col xl="6">
                <Label>Return Package</Label>
                <Input type="text" className="form-control" name="returnPackageAwb" onChange={formik.handleChange} onFocus={onFieldFocused} value={formik.values.returnPackageAwb} invalid={!!formik.errors.returnPackageAwb} />
                {!!formik.errors.returnPackageAwb && <FormFeedback type="invalid">{formik.errors.returnPackageAwb}</FormFeedback>}
              </Col>
            </Row>
            <div className="mb-4">
              <Label>Return Label (Upload PDF, PNG, JPG or JPEG) <span>{isReturnLabelRequired && '*'}</span></Label>
              <Input
                type="file"
                className="form-control"
                name="returnShippingLabel"
                onChange={(e) => formik.setFieldValue("returnShippingLabel", e.currentTarget.files[0])}
                onFocus={onFieldFocused}
                invalid={!!formik.errors.returnShippingLabel}
              />
              {!!formik.errors.returnShippingLabel && (
                <FormFeedback type="invalid">{formik.errors.returnShippingLabel}</FormFeedback>
              )}
              {!!defaultValues.returnShippingLabel && <a href={getBeUrl(`order/${id}/return-label/render`)} className='d-inline-block mt-2' target="_blank" rel="noreferrer">{!!defaultValues.hasMavShippingLabel ? 'Print Dealer Return Label' : defaultValues.returnShippingLabel}</a>}
            </div>
          </div>
        </Form>
      </CardBody>
    </Card>
  </React.Fragment>
}

FormEditTrackingAndShipping.propTypes = {
  id: PropTypes.number,
  defaultValues: PropTypes.object,
  finishedHandler: PropTypes.func,
}

export default FormEditTrackingAndShipping;