import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Card, CardBody, Button, Table, Alert, CardHeader } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { route, routes } from "helpers/routeHelper";
import Col from "components/Shared/Col";
import { doOrderDocListCleanup, doOrderFormCleanup, doOrderSingleCleanup, getFullOrder, getOrderDocList } from "store/actions";
import AccessDenied from "pages/Error/AccessDenied";
import { perms, useAccess } from "context/access";
import Preloader from "components/Shared/Preloader";
import Error from "pages/Error";
import FormSteps from "pages/Order/Partial/Form/Steps";
import Order from "model/order";
import { formatPhone, getBeUrl, getNotarizationRequired, getOrderSteps, showError, showSuccess } from "helpers/utilHelper";
import esigndoc from 'assets/images/esigndoc.svg';
import inksigndoc from 'assets/images/inksigndoc.svg';
import filedoc from 'assets/images/filedoc.svg';
import previewdoc from 'assets/images/previewdoc.svg';
import supportdoc from 'assets/images/supportdoc.svg';
import SpinnerChase from "components/Shared/SpinnerChase";
import { ServerErrorException, UNABLE_SEND_NEW_ORDER_CUSTOMER_NOTIF } from "helpers/errorHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import { estimateOrderFees, submitOrder } from "helpers/backendHelper";
import SpecialInstructionsLabel from "../Partial/View/SpecialInstructionsLabel";
import AdditionalFee from "../../../model/additionalFee";
import DealerGroup from "model/dealerGroup";
import { useAuth } from "context/auth";
import classnames from "classnames";
import OrderDoc from "model/orderDoc";
import MarketingBanner from "pages/MarketingPages/Partial/Section/Banner";

const NewConfirmation = () => {

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  const { getUserRoleClass } = useAuth();

  // read route params
  let { id } = useParams();
  // params are strings by default however we need ID as in int
  id = parseInt(id);

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);
  const { orderDocs, orderDocsError, isDocsLoadInProgress } = useSelector(state => state.OrderDoc.List);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  // next/prev routes used for wizard navigation
  const [navRoutes, setNavRoutes] = useState({ next: routes.view_order, prev: null });

  // check if the step from FormSteps component was clicked
  const [isStepSelected, setIsStepSelected] = useState({});

  const handlerStepChanged = (data) => setIsStepSelected(data);

  const [orderFees, setOrderFees] = useState(null);

  const steps = order ? getOrderSteps(order) : [];
  const numOfCols = steps.length;

  // check if the group special instructions exist and applies to dealers
  const isGroupSpecialInstructionsAppliesTo = () => order?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER);
  const showGroupSpecialInstructions = () => order?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // fetch the order data
    dispatch(getFullOrder(id));
    getFees(id);
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
      dispatch(doOrderFormCleanup());
    }
  }, [id]);

  // runs once when order is loaded
  useEffect(() => {
    if (!order) {
      return;
    }
    dispatch(getOrderDocList(id));
    return () => {
      dispatch(doOrderDocListCleanup());
    }
  }, [order]);

  // runs whenever the order changes
  // which is when the order is fetched from remote
  useEffect(() => {
    if (order && order.status != Order.STATUS_DRAFT) {
      // editing an order via this wizard is only allowed while the order is in DRAFT mode
      // (order is not yet submitted and the user is navigating back and forth through the wizard)
      // after that editing must be done from the view-order screen
      navigate(route(routes.view_order, order.id));
    }
  }, [order]);

  // runs whenever the 'isStepSelected' flag changes
  // which happens after a step was selected from the FormSteps(wizard navigation) component
  useEffect(() => {
    const selectedStepIndex = isStepSelected.stepIndex;
    const stepIndex = steps.findIndex(step => step.key === Order.ORDER_CREATION_STEP_CONFIRM);

    if (selectedStepIndex < stepIndex) handlerGoPrev(selectedStepIndex);

  }, [isStepSelected]);

  /********** EVENT HANDLERS **********/

  const handleSubmitOrder = () => {
    setIsSaveInProgress(true);
    submitOrder(order.id)
      .then(response => {
        // Save a cookie for hotjar events to know when they first accessed the page
        document.cookie = "newOrder=true; Path=/;"
        showSuccess(`Order "#${order.id}" has been submitted`);
        navigate(route(navRoutes.next, order.id));
      })
      .catch(ex => {
        if (ex instanceof ServerErrorException) {
          if (ex.code == UNABLE_SEND_NEW_ORDER_CUSTOMER_NOTIF) {
            // order has been submitted but the notifications could not be sent (at least some of them)
            // we want to make this distinction because the owner might want to resend notifications manually
            showError('Unable to send notifications');
            navigate(route(routes.list_orders));
            return;
          }
        }
        showError('Unable to submit order');
      })
      .finally(() => {
        setIsSaveInProgress(false);
      });
  };

  const getDownloadUrl = () => getBeUrl(`order/${order.id}/return-label/download`);

  const getFees = orderId => {
    estimateOrderFees(orderId)
      .then(response => {
        setOrderFees(response.fees);
      })
      .catch(ex => { });
  }

  const getIcon = orderDoc => {
    switch (orderDoc.type) {
      case OrderDoc.TYPE_E_SIGNED:
        return esigndoc;
      case OrderDoc.TYPE_INK_SIGNED:
        return inksigndoc;
      case OrderDoc.TYPE_PREVIEW:
        return previewdoc;
      case OrderDoc.TYPE_SUPPORTING:
        return supportdoc;
    }
  }

  const handlerGoPrev = (selectedStepIndex) => {
    if (!!navRoutes?.availablePrevs) {
      navigate(route(navRoutes.availablePrevs[selectedStepIndex].route, order.id));
    }
  }

  return <React.Fragment>
    {iAmGranted(perms.create_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>New order - Order Summary</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs()} />
            </Col>
            <Col xxl="4" className='text-end d-flex align-items-center justify-content-end mb-3 col-xxl-4'>
              {showGroupSpecialInstructions() && <SpecialInstructionsLabel instructions={order.dealerGroupSpecialInstructions} />}
            </Col>
          </Row>
          <MarketingBanner path="fedex" redirect={route(routes.view_fedex)} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent pt-3 pb-0">
                  <Row>
                    <Col>
                      <div className="card-title pt-1 pb-4 mb-0">Create new order</div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <FormSteps currentStep={Order.ORDER_CREATION_STEP_CONFIRM} order={order} setNavRoutes={setNavRoutes} onChange={handlerStepChanged} />
                  <div className="card-section px-0">
                    <div className="d-flex p-3 pt-4">
                      <div style={{ width: `${Math.floor(100 / numOfCols)}%` }} className="order-signers m-1">
                        <div className="card-title mb-2">Customer Information</div>
                        <p className="mb-1">Name: <strong>{order.signers[0].firstName} {order.signers[0].lastName}</strong></p>
                        <p className="mb-1">Email: <strong>{order.signers[0].email}</strong></p>
                        <p className="mb-1">Phone: <strong>{formatPhone(order.signers[0].phone)}</strong></p>
                        {order.signers.length > 0 && !!order.signers[0].address && <div className="p-1">
                          Address where signing will take place:
                          <ul className="mb-0">
                            <li>Address: <strong>{order.signers[0].address}</strong></li>
                            <li>City: <strong>{order.signers[0].city}</strong></li>
                            <li>State or Province: <strong>{order.signers[0].state}</strong></li>
                            <li>ZIP Code: <strong>{order.signers[0].zip}</strong></li>
                            {!!order.signers[0].address2 &&
                              <li>Additional info: <strong>{order.signers[0].address2}</strong></li>
                            }
                          </ul>
                        </div>}
                        {order.signers.length === 2 && <div className="additional-section m-2">
                          Additional Signer Information:
                          <ul className="mb-0">
                            <li>Name: <strong>{order.signers[1].firstName} {order.signers[1].lastName}</strong></li>
                            <li>Email: <strong>{order.signers[1].email}</strong></li>
                            <li>State or Province: <strong>{order.signers[1].state}</strong></li>
                            <li>Phone: <strong>{order.signers[1].phone}</strong></li>
                          </ul>
                        </div>}
                      </div>
                      <div style={{ width: `${Math.floor(100 / numOfCols)}%` }} className="order-vehicles m-1 d-flex flex-column justify-content-between">
                        {order.vehicles.map((vehicle, index) => {
                          if (index === 0) {
                            return <div key={index}>
                              <div className="card-title mb-2">Vehicle Information</div>
                              <p className="mb-1">Stock Number: <strong>{vehicle.stockNum}</strong></p>
                              <p className="mb-1">V.I.N. #: <strong>{vehicle.vin}</strong></p>
                              <p>Vehicle year, make, model: <strong>{vehicle.yearMakeModel || "--"}</strong></p>
                            </div>
                          }
                          return <div key={index} className="additional-section m-2">
                            Additional Vehicle Information:
                            <ul className="mb-0">
                              <li>Stock Number: <strong>{vehicle.stockNum}</strong></li>
                              <li>V.I.N. #: <strong>{vehicle.vin}</strong></li>
                              <li>Vehicle year, make, model: <strong>{vehicle.yearMakeModel || "--"}</strong></li>
                            </ul>
                          </div>
                        })}
                      </div>
                      {(order.isEsignRequired || order.isInkSignRequired) && <div style={{ width: `${Math.floor(100 / numOfCols)}%` }} className="contract-information m-1">
                        <div className="card-title mb-2">Contract Information</div>
                        {order.isNotaryRequired && order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING && (
                          <>
                            <p className="mb-1">Date when documents will be sent to Notary <strong>{formatTimestamp(order.docsSentToNotaryTs, formats.DATETIME)}</strong></p>
                          </>
                        )}
                        {order.isNotaryRequired && <>
                          <p className="mb-1">Items to Collect: <strong>{order.itemsToCollect ? order.itemsToCollect.map(item => Order.getItemToCollectName(item)).join(", ") : "--"}</strong></p>
                          <p className="mb-1">Additional Items to Collect: <strong>{order.additionalItemsToCollect || "--"}</strong></p>
                          <p className="mb-1">Dealer Special Instructions: <strong>{order.dealerInstructions || "--"}</strong></p>
                          <p className="mb-1">Label Generation: <strong>{order.hasMavShippingLabel ? "Label generated with Mavsign" : "Label generated by the dealership"}</strong></p>
                        </>}
                        <p className="mb-1">Notary involved: <strong>{order.isNotaryRequired ? 'Yes' : 'No'}</strong></p>
                        {order.isInkSignRequired && (<p className="mb-1">Return Label: {!!order.returnShippingLabel ?
                          <a href={getDownloadUrl()} className='ms-2'>returnlabel<i className="ms-2 bx bx-download"></i></a> :
                          <strong>--</strong>}
                        </p>)}
                        {order.isNotaryRequired && (
                          <>
                            {!!order.signers && order.signers.length > 1 && <div className="mb-1">Specific Signing Instructions:
                              <ul>
                                <li>Signer 1: <strong>{order.signer1Instructions || "--"}</strong></li>
                                <li>Signer 2: <strong>{order.signer2Instructions || "--"}</strong></li>
                              </ul>
                            </div>}
                          </>)
                        }
                      </div>}
                      {(order.isEsignRequired || order.isInkSignRequired) && <div style={{ width: `${Math.floor(100 / numOfCols)}%` }} className="contract-information m-1">
                        {order.isNotaryRequired && <>
                          <p className="mb-1">Document delivery option: <strong>{Order.getDocDeliveryOptions()[order.docDeliveryOption]}</strong></p>
                          {order.isNotaryRequired && order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING && (
                            <>
                              <p className="mb-1">Shipper: <strong>{Order.getShippingCompanyName(order.shippingCompany) || "--"}</strong></p>
                            </>
                          )}
                          <p className="mb-1">Dealer Special Instructions: <strong>{order.dealerInstructions || "--"}</strong></p>
                          {
                            !!order.hasMavShippingLabel &&
                            <>
                              {
                                order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING ?
                                  <p className="mb-1 font-weight-600">Your Mavsign generated label will be emailed and provided in the order, once created.</p>
                                  :
                                  <p className="mb-1 font-weight-600">The Mavsign generated return label will be emailed to the notary</p>
                              }
                            </>
                          }
                        </>}
                      </div>}
                    </div>
                  </div>
                  <div className="card-section">
                    {(order.isEsignRequired || order.isInkSignRequired) && <React.Fragment>
                      <Row className="mb-2">
                        <Col>
                          {order.isNotaryRequired && <div className="card-title mb-2">The following are the documents you should expect back from the notary:</div>}
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col>
                          <Table className="table mb-0 bb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Document name</th>
                                <th>Document type</th>
                                <th>Requires Notarization</th>
                                <th>No of pages</th>
                                <th>No of signatures</th>
                                <th>No of initials</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isDocsLoadInProgress && <tr>
                                <td colSpan="6">
                                  <SpinnerChase className="sm mt-2 mb-2" />
                                </td>
                              </tr>}
                              {orderDocsError && <tr>
                                <td colSpan="6"><Alert color="danger" className="fade show text-center mb-0">
                                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load documents
                                </Alert>
                                </td>
                              </tr>}
                              {!isDocsLoadInProgress && !orderDocsError && orderDocs.map((doc, index) => {
                                return <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td><img src={filedoc} className="me-2" />{doc.name}</td>
                                  <td>{!!getIcon(doc) && <img src={getIcon(doc)} className="me-2" />}{doc.typeName}</td>
                                  <td>{getNotarizationRequired(doc.isNotarizationRequired)}</td>
                                  <td>{doc.numOfPages}</td>
                                  <td>{doc.numOfSignatures}</td>
                                  <td>{doc.numOfInitials}</td>
                                </tr>;
                              })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </React.Fragment>}
                    <Row className="mb-2">
                      <Col className="text-end pt-4">
                        {navRoutes.prev && <Link to={route(navRoutes.prev, order.id)} className="btn btn-primary btn-faded">Previous</Link>}
                        <Button type="button" color="primary" className={classnames('ms-2', `${getUserRoleClass()}-created-order`)} onClick={handleSubmitOrder} disabled={isSaveInProgress}>
                          {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>}
      {isLoadInProgress && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>}
    {iAmNotGranted(perms.create_orders) && <AccessDenied />}
  </React.Fragment>
}

const breadcrumbs = id => [{
  title: 'ORDERS',
  url: route(routes.list_orders),
}, {
  title: 'New order',
  url: route(routes.new_order),
}, {
  title: 'Order confirmation',
}];

export default NewConfirmation;